import { Box, Button, Divider, IconButton, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import log from "../misc/Logger";
import { Context } from '../App';
import { ActionSetExerciseIDLastCRUD, ActionSetPlaybook, ActionSetSelectedExercisePool, ActionSetSelectedGroup, CRUD, DrupalEntity, JSONAPITypeId, OperationMode, TypeContext, UserType } from '../misc/Types';
import { useTranslation } from 'react-i18next';
import { Fragment, useContext, useEffect, useState } from 'react';
import { exerciseImage, exerciseVideo, getActionSetConfirm, getAllContentEntities, getDD, getNodeWithRemovedRelationship, getPlaybookSharable, icbControllerGenerel02, nodeCRUD } from '../misc/Functions';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import { useLocation, useNavigate } from 'react-router-dom';
import DialogPlayBuilder, { PlayDetails } from './DialogPlayBuilder';
import { BACKEND, DRUPALENTITYINIT, IMAGEMIMETYPES, PLAYDETAILSINIT, VIDEOMIMETYPES } from '../misc/Constants';
import PlayIllustrate from './PlayIllustrate';
import CloseIcon from '@mui/icons-material/Close';
import mime from 'mime';
import DialogGetSingleLineOfText from './DialogGetSingleLineOfText';

export default function CreatePlay() {
    const { state, dispatch } = useContext(Context) as TypeContext;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const play: DrupalEntity = location.state?.play || DRUPALENTITYINIT
    log.debug('CreatePlay');

    const [disabled, setDisabled] = useState(false)
    const [openDialogPlayBuilder, setOpenDialogPlayBuilder] = useState(false)
    // const [playDetails, setPlayDetails] = useState(JSON.parse(JSON.stringify(PLAYDETAILSINIT)))
    const [playDetails, setPlayDetails] = useState<PlayDetails | null>(null)
    const [imageFile, setImageFile] = useState<File | null>(null);
    const [imageURL, setImageURL] = useState<string | null>();
    const [videoFile, setVideoFile] = useState<File | null>(null);
    const [videoURL, setVideoURL] = useState<string | null>(); // 
    const [openDialogGetSingleLineOfText, setOpenDialogGetSingleLineOfText] = useState(false)
    const [newUserDefinedGroup, setNewUserDefinedGroup] = useState('')

    log.debug('CreatePlay');

    // get default play details from passed in entity
    useEffect(() => {
        if (play.attributes.field_play_details || play.attributes.field_exercise_details) {
            setPlayDetails(JSON.parse(play.attributes.field_play_details || play.attributes.field_exercise_details))
        }
    }, [])

    // set exercise image and video url as read from the back end
    useEffect(() => {
        if (!imageURL && play.relationships.field_exercise_board_image)
            setImageURL(play?.relationships.field_exercise_board_image.data?.id && exerciseImage(state, play.relationships.field_exercise_board_image.data?.id));
        if (!videoURL && play.relationships.field_exercise_video)
            setVideoURL(play?.relationships.field_exercise_video.data?.id && exerciseVideo(state, play.relationships.field_exercise_video.data?.id));
    }, [state.allFiles])

    // TODO this does not work - i try to set a value in the formik form
    useEffect(() => {
        if (newUserDefinedGroup)
            formik.setFieldValue('field_play_group', newUserDefinedGroup)
    }, [newUserDefinedGroup])

    const color = state.operationMode === OperationMode.play ? "primary" : "secondary"

    const validationSchema = yup.object({
        title: yup
            .string()
            .required(t('CreatePlay00'))
            // allow save if no other node with same title or node with title has same id as the one we try to save
            .test('new-play', t("CreatePlay10"), (value) => {
                const rc = state.allPlays.find(x => x.attributes.title === value) === undefined
                    || state.allPlays.find(x => x.attributes.title === value)?.id === play.id
                return rc
            }),
        field_play_description: yup
            .string(),
        field_play_group: yup
            .string()
            .required(t('CreatePlay01')),
    });

    const formik = useFormik({
        initialValues: {
            title: (play.attributes?.title || '') as string,
            field_play_description: (play.attributes.field_play_description || play.attributes.field_description || '') as string,
            field_play_group: (play.relationships.field_play_group?.data.id || play.relationships.field_group?.data.id || '') as string,
        },
        validationSchema: validationSchema,
        // create new or update play/exercise
        onSubmit: async (values) => {
            if (disabled)
                return
            setDisabled(true)

            // set the play/exercise we want to create/update
            let node: DrupalEntity = DRUPALENTITYINIT
            if (state.operationMode === OperationMode.play) {
                node = {
                    type: 'node--play',
                    attributes: {
                        title: values.title,
                        field_play_description: values.field_play_description,
                        field_play_details: JSON.stringify(playDetails),
                    },
                    relationships: {
                        field_play_group: {
                            data: {
                                type: 'node--play',
                                id: values.field_play_group
                            }
                        }
                    }
                }
            } else {
                node = {
                    type: 'node--exercise',
                    attributes: {
                        title: values.title,
                        field_description: values.field_play_description,
                        field_exercise_details: playDetails ? JSON.stringify(playDetails) : null,
                    },
                    relationships: {
                        field_group: {
                            data: {
                                type: 'node--play',
                                id: values.field_play_group
                            }
                        },
                        // delete existing image?
                        field_exercise_board_image: play.relationships?.field_exercise_board_image?.data?.id && !imageFile && !imageURL ?
                            {} // delete existing image
                            : play.relationships.field_exercise_board_image, // keep existing image
                        // delete existing video?
                        field_exercise_video: play.relationships?.field_exercise_video?.data?.id && !videoFile && !videoURL ?
                            {} // delete existing video
                            : play.relationships.field_exercise_video, // keep existing video
                    }
                }
            }

            // create or update?
            let CRUDOperation: CRUD = CRUD.Create // assume create new play
            if (play.id) {
                CRUDOperation = CRUD.Update // no, update play
                node['id'] = play.id
            }

            // free user is only allowed to create a certain number of plays/exercises - ToDo for now we use same limit for plays and exercises
            const data = state.operationMode === OperationMode.play ? state.allPlays : state.allPractices
            if (state.user.data.attributes.field_user_type === UserType.free
                && CRUDOperation === CRUD.Create
                && data.filter(x => x.relationships.uid.data.id === state.user.data.id).length >= (state.configuration[0].attributes.field_max_plays_free_user || 3)) {
                dispatch(getActionSetConfirm(t('AlertMsg41'), t('ICBAppBar04'), () => navigate('/setsubscription')));
                setDisabled(false)
                return
            }

            // update node
            const respNode = await nodeCRUD(state, dispatch, CRUDOperation, node)
            if (!respNode.data) {
                dispatch(getActionSetConfirm(respNode));
                setDisabled(false)
                return;
            }

            // attatch video file to exercise
            if (state.operationMode === OperationMode.exercise && videoFile && videoURL) {
                const resp = await nodeCRUD(state, dispatch, CRUD.Update, respNode.data, videoFile, "field_exercise_video")
                if (!resp.data) {
                    dispatch(getActionSetConfirm(resp));
                    setDisabled(false)
                    return;
                }
            }

            // attatch image file to exercise
            if (state.operationMode === OperationMode.exercise && imageFile && imageURL) {
                const resp = await nodeCRUD(state, dispatch, CRUD.Update, respNode.data, imageFile, "field_exercise_board_image")
                if (!resp.data) {
                    dispatch(getActionSetConfirm(resp));
                    setDisabled(false)
                    return;
                }
                // Later, when we show exericse then request image from CDN a number of times to allow for conversion
                let action: ActionSetExerciseIDLastCRUD = { type: 'setExerciseIDLastCRUD', id: respNode.data.id }
                dispatch(action);
            }

            // if clubuser creates play/exercise then clone to clubadmin and get clone
            if (CRUDOperation === CRUD.Create
                && state.user.data.attributes.field_user_type === UserType.club
                && state.user.data.attributes.field_club_admin_accept) {
                const resp = await getDD(state, dispatch, `${BACKEND}/icb-node/clone-node-to-clubadmin/${respNode.data.attributes.drupal_internal__nid}`, 'GET')
                if (!resp.ok) {
                    dispatch(getActionSetConfirm(resp));
                    setDisabled(false)
                    return;
                }
                getAllContentEntities(state, dispatch, `node--${state.operationMode === OperationMode.play ? 'play' : 'exercise'}`, `?filter[nid]=${resp.nid_dest}`) // fetch cloned play
            }

            // update playbook if play is in playbook
            if (state.operationMode === OperationMode.play) {
                // Find published playbooks that holds the updated play 
                const playbooksRepublish = state.allPlaybooks.filter(x => CRUDOperation === CRUD.Update
                    && x.attributes.field_url_and_credentials
                    && x.relationships.field_plays.data.map((x: JSONAPITypeId) => x.id).includes(play.id))
                playbookRepublish(playbooksRepublish, respNode.data, false)
            }

            // re-fetch the node to get propper relations, ie. exercise image and video
            if (state.operationMode === OperationMode.play)
                getAllContentEntities(state, dispatch, 'node--play', `?filter[id]=${respNode.data.id}`)
            else
                getAllContentEntities(state, dispatch, 'node--exercise', `?filter[id]=${respNode.data.id}&include=field_exercise_board_image,field_exercise_video`)

            gotoPlay(values.field_play_group)
        }
    });

    // Try to go where new/edited play/exercise is and show play/exercise to user!
    function gotoPlay(groupID: string) {
        let actionSetSelectedExercisePool: ActionSetSelectedExercisePool = {
            type: 'setSelectedExercisePool',
            pool: state.user.data.attributes.field_user_type === UserType.clubadmin ? 'club' : 'mine'
        }
        dispatch(actionSetSelectedExercisePool);
        let actionSetSelectedGroup: ActionSetSelectedGroup = { type: 'setSelectedGroup', group: state.allGroups.find(x => x.id === groupID)! };
        dispatch(actionSetSelectedGroup);
        if (state.operationMode === OperationMode.play)
            navigate('/icbplays');
        else
            navigate('/icbdrills');
        // Make sure full width display is available to show plays/exercises
        if (state.showPracticeProgram && state.portrait) {
            let action = { type: 'showPracticeProgram', show: false };
            dispatch(action);
        }
    }

    /*
    when a play is updated or deleted we must handle the situation where the play is in a playbook. If the play is in playbooks
    that only holds plays by the user we unconditionally republish the playbooks. The the play is in playbooks that hold plays
    by ohter users, ie clubadmin or icb 7, then we give option to republish playbooks. All this because published plays by the 
    user SHOULD be approved by the user and we risk that a play by anohter user has been updated and then we can not unconditionally
    republish playbooks.
    If we call getDD() below to republish a playbook then we pass in an updated play or a playbook with reduced list of plays depending
    on playDeleted. This is because getDD() works on global state and global state has not been uddated yet.
    republishCandidates: the list of playbooks in question
    play: updated or deleted play
    playDeleted: play is deleted. Otherwise, play is updated
    */
    function playbookRepublish(republishCandidates: DrupalEntity[], play: DrupalEntity, playDeleted: boolean) {
        // The list of playbooks we want to update - does it contain plays from other authors than current user?
        const playbooksRepublishOtherAuthors = republishCandidates.filter(
            x => x.relationships.field_plays.data.map((x: JSONAPITypeId) => x.id) // get list of playIDs in playbook
                .find((x: string) => state.allPlays
                    .find(y => y.id === x && y.relationships.uid.data.id !== state.user.data.id))) // based on playID find play that is NOT by current user
        if (playbooksRepublishOtherAuthors.length > 0) {
            // confirm republish
            // user must accept replublish of paybooks because playbooks contains plays user does not control and these plays might have been updated
            // and the user should not have plays in playbooks he has not checked

            const msg = t('CreatePlay11')
            // const msg = `These playbooks will be republished: ${republishCandidates.map(item => item.attributes.title)}. These playbooks contain plays you dont control: ${playbooksRepublishOtherAuthors.map(item => item.attributes.title)}. Go ahead and republish playbooks?`

            dispatch(getActionSetConfirm(msg, 'ok', () => {
                // Republish these playbooks
                republishCandidates.forEach(x => {
                    log.info('republish playbook because play has been updated/deleted. Play:', play.attributes.title, 'Playbook:', x.attributes.title)
                    if (playDeleted)
                        getPlaybookSharable(state, getNodeWithRemovedRelationship(x, 'field_plays', play.id!))
                            .then(playbook => getDD(state, dispatch, `${BACKEND}/icb-playbook/create/${x.attributes.drupal_internal__nid}`, 'POST', playbook))
                    else
                        getPlaybookSharable(state, x, play)
                            .then(playbook => getDD(state, dispatch, `${BACKEND}/icb-playbook/create/${x.attributes.drupal_internal__nid}`, 'POST', playbook))
                })
            }))
        } else if (republishCandidates.length > 0) {
            // republish unconditionally
            republishCandidates.forEach(x => {
                log.info('republish playbook because play has been updated/edited. Play:', play.attributes.title, 'Playbook:', x.attributes.title)
                if (playDeleted)
                    getPlaybookSharable(state, getNodeWithRemovedRelationship(x, 'field_plays', play.id!))
                        .then(playbook => getDD(state, dispatch, `${BACKEND}/icb-playbook/create/${x.attributes.drupal_internal__nid}`, 'POST', playbook))
                else
                    getPlaybookSharable(state, x, play)
                        .then(playbook => getDD(state, dispatch, `${BACKEND}/icb-playbook/create/${x.attributes.drupal_internal__nid}`, 'POST', playbook))
            })
        }
    }

    // delete play and remove play from playbooks if needed. Updated playbooks are republished
    function handleDelete() {
        if (state.operationMode === OperationMode.play) {
            dispatch(getActionSetConfirm(t('CreatePlay12'), 'ok', () => {
                // delete play and remove play from playbooks. Return list of updated playbooks
                icbControllerGenerel02(state, {
                    opr: "delete_play",
                    playID: play.id
                })
                    .then((resp) => {
                        if (resp.ok) {
                            // we got a list of playbooks that were updated because of the deleted play
                            // Find published playbooks that holds the updated play
                            const playbooksRepublish = state.allPlaybooks.filter(x => resp.playbooksUpdated.includes(x.attributes.drupal_internal__nid.toString())) // toString() because that is what we get from the back end
                            playbookRepublish(playbooksRepublish, play, true)

                            // Remove play from list of read plays now that play has been deleted in the back end
                            // dispatch({ type: 'delContentEntity', contentType: 'node--play', id: play.id })
                            getAllContentEntities(state, dispatch, 'node--play')

                            // If back end call to opr: "delete_play" updated playbooks then reread playbooks
                            if (resp.playbooksUpdated.length > 0)
                                getAllContentEntities(state, dispatch, 'node--playbook')

                            // delete play from current playbook if needed as we might show current playbook
                            if (state.curPlaybook.relationships.field_plays?.data.find((x: JSONAPITypeId) => x.id === play.id)) {
                                const action: ActionSetPlaybook = { type: 'setPlaybook', playbook: getNodeWithRemovedRelationship(state.curPlaybook, 'field_plays', play.id!) }
                                dispatch(action)
                            }

                            // gotoPlay(play.relationships.field_play_group.data.id)
                            navigate(-1)
                        } else {
                            dispatch(getActionSetConfirm(resp.error))
                        }
                    })
            }));
        } else {
            dispatch(getActionSetConfirm(t('ExerciseCreate18'), 'ok', async () => {
                await nodeCRUD(state, dispatch, CRUD.Delete, {
                    type: 'node--exercise',
                    id: play.id,
                })
                navigate(-1)
            }));

        }
    }

    // handle upload of exercise image
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            if (['image/png', 'image/gif', 'image/jpg', 'image/jpeg',].includes(event.target.files[0].type)) {
                if (event.target.files[0].size > 8000000)
                    dispatch(getActionSetConfirm(t('AlertMsg27')));
                else {
                    setImageFile(event.target.files[0]);
                    const url = URL.createObjectURL(event.target.files[0]);
                    setImageURL(url);
                }
            } else {
                dispatch(getActionSetConfirm(t('AlertMsg26')));
            }
        }
    };

    // handle change of exercise video
    function handleVideoFileChange(event: React.ChangeEvent<HTMLInputElement>) {
        log.debug('handleVideoFileChange, upload video type: ' + event.target.files![0].type + '. Name: ' + event.target.files![0].name + '. Size: ' + event.target.files![0].size);
        if (event.target.files && event.target.files.length > 0) {
            const mimeType = mime.getType(event.target.files[0].name) || ''
            if (VIDEOMIMETYPES.includes(mimeType)) { //    event.target.files[0].type))
                // Size limit. Set both upload_max_filesize and post_max_size in /etc/php/8.1/fpm/php.ini
                if (event.target.files[0].size > 128000000)// 8000000)
                    dispatch(getActionSetConfirm(t('AlertMsg28')));
                else {
                    setVideoFile(event.target.files[0]);
                    const url = URL.createObjectURL(event.target.files[0]);
                    setVideoURL(url);
                }
            } else {
                dispatch(getActionSetConfirm(t('AlertMsg25')));
            }
        }
    };

    // handle create new play or exercise group
    async function onCloseDialogGetSingleLineOfText(ok: boolean, text: string) {
        if (ok) {
            // User clicked OK to create new exercise group
            if (state.allGroups.find(x => x.attributes.field_group_type === state.operationMode && x.attributes.title === text)) {
                dispatch(getActionSetConfirm(t('ExerciseCreate19')));
            } else if (text.trim() === '') {
                dispatch(getActionSetConfirm(t('ExerciseCreate20')));
            } else {
                // Create group
                const group = {
                    "type": "node--group",
                    "attributes": {
                        "title": text,
                        "field_group_type": state.operationMode,
                    },
                }
                const resp = await nodeCRUD(state, dispatch, CRUD.Create, group);
                if (!resp.data) {
                    dispatch(getActionSetConfirm(resp));
                    return
                }
                // Insert new group in form
                // handleChange({ target: { name: "field_group", value: resp.data.attributes.title } })
                setNewUserDefinedGroup(resp.data.attributes.title)
            }
        }
        setOpenDialogGetSingleLineOfText(false)
    }

    return (
        <Box sx={{ width: '90%', maxWidth: '700px', margin: 'auto', paddingTop: 2 }}>
            <Typography paddingBottom={2} color={color} sx={{ fontFamily: 'PT Sans, sans-serif', fontSize: { xs: '20px', sm: '25px' }, fontWeight: 'bold' }}>
                {state.operationMode === OperationMode.play ? t('CreatePlay02') : t('ExerciseCreate10')}
            </Typography>
            <Divider />
            <form onSubmit={formik.handleSubmit}>
                <TextField
                    fullWidth
                    id="title"
                    name="title"
                    label={t('CreatePlay03')}
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.title && Boolean(formik.errors.title)}
                    helperText={formik.touched.title && formik.errors.title}
                    size="small"
                    sx={{ marginTop: 3 }}
                    inputProps={{ maxLength: 45 }}
                />
                <TextField
                    fullWidth
                    multiline
                    id="field_play_description"
                    name="field_play_description"
                    label={t('CreatePlay04')}
                    type="field_play_description"
                    value={formik.values.field_play_description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.field_play_description && Boolean(formik.errors.field_play_description)}
                    helperText={formik.touched.field_play_description && formik.errors.field_play_description}
                    size="small"
                    sx={{ marginTop: 2 }}
                    inputProps={{ maxLength: 600 }}
                />
                <Stack
                    direction={'row'}>
                    <TextField
                        fullWidth
                        select
                        id="field_play_group"
                        name="field_play_group"
                        label={t('CreatePlay05')}
                        type="field_play_group"
                        value={state.allGroups.length === 0 ? '' : formik.values.field_play_group} // don't use default value until select has default value
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.field_play_group && Boolean(formik.errors.field_play_group)}
                        helperText={formik.touched.field_play_group && formik.errors.field_play_group}
                        size="small"
                        sx={{ marginTop: 2 }}
                    >
                        {/* get list of all play or exercise groups */}
                        {
                            state.allGroups.filter(x => x.attributes.field_group_type === state.operationMode).map((item, index) => {
                                return (
                                    <MenuItem
                                        key={index}
                                        value={item.id}
                                    >
                                        {item.attributes.title}
                                    </MenuItem>
                                )
                            })
                        }
                    </TextField>

                    {/* Create new group, only for clubadmin */}
                    {
                        [UserType.clubadmin].includes(state.user.data.attributes.field_user_type) &&
                        <Button
                            onClick={() => setOpenDialogGetSingleLineOfText(true)}
                        >
                            {t('Groups01')}
                        </Button>
                    }
                </Stack>

                {/* Create/Edit play button */}
                {/* IN THIS ONE WE IMPORT THE OPEN MODAL FUNCTION FROM PLAYBUILDER */}
                <Button
                    color={color}
                    variant="contained"
                    fullWidth
                    onClick={() => {
                        // either uploaded file or play - not both
                        if (imageFile || imageURL)
                            dispatch(getActionSetConfirm(t('remove uploaded file'), 'ok', () => {
                                setOpenDialogPlayBuilder(true)
                                setImageFile(null)
                                setImageURL(null)
                            }))
                        else
                            setOpenDialogPlayBuilder(true)
                    }}
                    sx={{
                        textTransform: 'none',
                        marginTop: 2,
                        justifyContent: 'flex-start' // Align icon and text to the left
                    }}
                >
                    <BorderColorRoundedIcon sx={{ marginRight: 1 }} /> {/* Add space between icon and text */}
                    {
                        play.id ? t('CreatePlay06') : t('CreatePlay07')
                    }
                </Button>

                {/* illustrate play if play is not initial */}
                {
                    playDetails && <PlayIllustrate play={DRUPALENTITYINIT} playDetails={playDetails!} />
                }

                {/* Exercise image */}
                {
                    // Upload or delete image
                    state.operationMode === OperationMode.exercise &&
                    <Fragment>
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: 15, width: '100%' }}>
                            {/* Upload image */}
                            <Button
                                key={'imagefile'}
                                variant="outlined"
                                component="label"
                                size="small"
                                color="secondary"
                                sx={{ width: '100%' }}
                            >
                                {/* Change image OR Upload image */}
                                {imageFile ? t('ExerciseCreate04') : t('ExerciseCreate03')}
                                <input
                                    type="file"
                                    id="uploadImageFile"
                                    accept={IMAGEMIMETYPES.toString()}
                                    hidden
                                    // accept="video/*" with this restriction desktop version does not find mov files
                                    onChange={(event) => {
                                        // either uploaded file or play - not both
                                        if (playDetails) {
                                            dispatch(getActionSetConfirm(t('delete play'), 'OK', () => {
                                                handleFileChange(event)
                                                setPlayDetails(null)
                                            }))
                                        } else {
                                            handleFileChange(event)
                                        }
                                    }}
                                />
                            </Button>

                            {/* Delete image */}
                            {imageURL && (
                                <IconButton
                                    onClick={() => {
                                        setImageFile(null);
                                        setImageURL(null);
                                        let input = document.getElementById('uploadImageFile') as HTMLInputElement;
                                        input.value = '';
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            )}
                        </div>

                        {/* Show uploaded image */}
                        {imageURL && (
                            <Box sx={{ m: 1 }}>
                                <img src={`${imageURL}`} alt="Uploaded Preview" style={{ width: '150px', height: '150px', marginTop: '1rem' }} />
                            </Box>
                        )}
                    </Fragment>
                }

                {/* Exercise video */}
                {
                    // Upload or delete video
                    state.operationMode === OperationMode.exercise &&
                    <Fragment>
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: 15, width: '100%' }}>
                            {/* Upload video */}
                            <Button
                                key={'videofile'}
                                variant="outlined"
                                component="label"
                                size="small"
                                color="secondary"
                                sx={{ width: '100%' }}
                            >
                                {/* Change video OR Upload video */}
                                {videoFile ? t('ExerciseCreate06') : t('ExerciseCreate05')}
                                <input
                                    type="file"
                                    id="uploadVideoFile"
                                    accept={VIDEOMIMETYPES.toString()}
                                    hidden
                                    // accept="video/*" with this restriction desktop version does not find mov files
                                    onChange={(event) => handleVideoFileChange(event)}
                                />
                            </Button>

                            {/* Delete video */}
                            {videoURL && (
                                <IconButton
                                    onClick={() => {
                                        setVideoFile(null);
                                        let input = document.getElementById('uploadVideoFile') as HTMLInputElement;
                                        input.value = '';
                                        setVideoURL(null);
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            )}
                        </div>

                        {/* Show uploaded video */}
                        {videoURL && (<Box sx={{ m: 1 }}>{videoFile?.name}</Box>)}
                        {videoURL && (
                            <Box sx={{ m: 1 }}>
                                <video src={videoURL}
                                    width="250"
                                    loop
                                    muted
                                    controls
                                    controlsList="nodownload"
                                />
                            </Box>
                        )}
                    </Fragment>
                }

                {/* Save (submit) and delete button */}
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 5 }}>
                    <Button sx={{ textTransform: 'none', fontWeight: 'bold' }} size="small" color={color} variant="contained" type="submit">
                        {t('CreatePlay08')}
                    </Button>
                    {
                        // show delete button if current play has an id. Otherwise, we are creating a new play
                        play.id &&
                        <Box sx={{ display: 'flex', marginLeft: 1 }}>
                            <Button sx={{ textTransform: 'none' }} color={color} variant="outlined" size="small" onClick={handleDelete}>
                                {state.operationMode === OperationMode.preplannedPractice ? t('CreatePlay09') : t('ExerciseCreate09')}
                            </Button>
                        </Box>
                    }
                </Box>

                <DialogPlayBuilder
                    // play={play}
                    title={play.attributes.title}
                    playDetails={playDetails ? playDetails : JSON.parse(JSON.stringify(PLAYDETAILSINIT))}
                    open={openDialogPlayBuilder}
                    onClose={(playDetails: PlayDetails | null) => {
                        if (playDetails) {
                            setPlayDetails(playDetails)
                        }
                        setOpenDialogPlayBuilder(false)
                    }}
                />

                {/* Dialogbox to add new exercise group */}
                <DialogGetSingleLineOfText
                    open={openDialogGetSingleLineOfText}
                    onClose={(ok, text) => onCloseDialogGetSingleLineOfText(ok, text)}
                    title={t('ExerciseCreate14')}
                    contentLine1={t('ExerciseCreate16')}
                    label={t('ExerciseCreate15')}
                    button={t('ExerciseCreate07')}
                />

            </form>
        </Box>
    );
}
