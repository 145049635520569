import { Fragment, Suspense, useContext, useRef, useState } from "react";
import { ActionSetPlaybook, ActionSetPractice, ActionSetPreplannedPractice, DrupalEntity, ExerciseSelected, JSONAPITypeId, OperationMode, TypeContext } from "../misc/Types";
import { Box, Typography, IconButton, Stack, Paper, Tooltip, Fab, TextField, CardMedia, Button, FormControl, InputLabel, Select, Input, MenuItem } from "@mui/material";
import { Context } from "../App";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { useTheme } from "@mui/material/styles";
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ArrowCircleUpRoundedIcon from '@mui/icons-material/ArrowCircleUpRounded';
import Grid2 from "@mui/material/Unstable_Grid2";
import SnackbarMessages from "./SnackbarMessages";
import PlaylistAddCheckCircleRoundedIcon from '@mui/icons-material/PlaylistAddCheckCircleRounded';
import AuthorImageAndName from "./AuthorImageAndName";
import { exerciseImage, getActionSetConfirm, getNodeWithUpdatedRelationship, getRelationshipsDataElementFor } from "../misc/Functions";
import Favorite from "./Favorite";
import PlayIllustrate from "./PlayIllustrate";
import ArrowCircleDownRoundedIcon from '@mui/icons-material/ArrowCircleDownRounded';
import DialogPlayAnimation from "./DialogPlayAnimation";
import log from '../misc/Logger';
import { lazy } from 'react'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import { DialogExerciseDetails } from "./DialogExerciseDetails";
import DialogExerciseDurationAndFocus from "./DialogExerciseDurationAndFocus";
import { DialogShowVideo } from "./DialogShowVideo";
import { DRUPALENTITYINIT, PLAYDETAILSINIT } from "../misc/Constants";
const GeneratePDFPlay = lazy(() => import('./GeneratePDFPlay'));

interface PropsPlayCard {
  play: DrupalEntity,         // play/exercise to show
  index?: number,             // position of play/exercise in list of selected plays/exercises. If undef then we show play/exercise that is not in selected list
  startAndStopTime?: string,  // show start and stop time for practice
}

// PlayCard. Show play or exercise card in tab interface or in selected list of plays or exercises
export default function PlayCard(props: PropsPlayCard) {
  const { state, dispatch } = useContext(Context) as TypeContext;
  const navigate = useNavigate();
  const { t } = useTranslation();
  log.debug('PlayCard');

  const theme = useTheme();
  const [openSnackbarMessage, setOpenSnackbarMessage] = useState(false);
  const [showPlayDescription, setShowPlayDescription] = useState(false);
  const [openDialogPlayAnimation, setOpenDialogPlayAnimation] = useState(false);
  const [openDialogExerciseDetails, setOpenDialogExerciseDetails] = useState(false)
  const [openDialogExerciseDurationAndFocus, setOpenDialogExerciseDurationAndFocus] = useState(false);
  const [conceptFileID, setConceptFileID] = useState('') // Controls open/close of DialogShowVideo
  const [concept, setConcept] = useState('') // Title for video dialog
  const [retries, setRetries] = useState(0)
  const [showConcepts, setShowConcepts] = useState(false);
  const imageRef = useRef<HTMLImageElement>(null);
  const fileIDVideo = props.play && state.operationMode === OperationMode.exercise && props.play.relationships.field_exercise_video?.data?.id;
  const fileIDImage = props.play && state.operationMode === OperationMode.exercise && props.play.relationships.field_exercise_board_image?.data?.id;
  const description = props.play && props.play.attributes[state.operationMode === OperationMode.play ? 'field_play_description' : 'field_description']
  // Get playdetails from play or exercise entity passed in. If available then turn into object. Otherwise keep value 'null'
  const _playDetails = props.play?.attributes.field_play_details || props.play?.attributes.field_exercise_details
  const playDetails = _playDetails ? JSON.parse(_playDetails) : null

  // Get the user defined name of a play
  function field_play_name_call_out() {
    if (state.operationMode === OperationMode.play) {
      try {
        const o = JSON.parse(state.curPlaybook.attributes.field_play_name_call_out || "{}")
        return o[props.play.id!]
      } catch (e) {
        return 'data error!'
      }
    } else
      return ""
  }

  // Set flag on new concept. Determined by configuration
  function newConcept(concept: DrupalEntity): boolean {
    let nowMinusXDays = Date.now() - parseInt(state.configuration[0].attributes.field_days_to_flag_new_concept) * 24 * 60 * 60 * 1000;
    return (Date.parse(concept.attributes.created) > nowMinusXDays || Date.parse(props.play.attributes.changed) > nowMinusXDays)
  }

  // dispatch action to update current practice or current practice draft depending on operation mode
  function dispatchSetPractice(selectedExercises: ExerciseSelected[]) {
    if (state.operationMode === OperationMode.exercise) {
      const action: ActionSetPractice = {
        type: 'setPractice', practice: {
          ...state.curPractice,
          selectedExercises: selectedExercises,
          dirty: true,
        }
      }
      dispatch(action)
    } else {
      const action: ActionSetPreplannedPractice = {
        type: 'setPreplannedPractice', preplannedPractice: {
          ...state.curPreplannedPractice,
          selectedExercises: selectedExercises,
        }
      }
      dispatch(action)
    }
  }

  // Return play as card in tab interface
  // Return exercise as card in tab interface
  // Return play as card in selected list
  // Return exercise as card in selected list
  return (
    <Fragment>
      {/* Play/Exercise card that is NOT selected for a playbook */}
      {
        props.index === undefined &&
        <Paper
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '350px',
            margin: 'auto',
            padding: state.portrait ? 1 : 1.5,
            borderRadius: state.portrait ? 2 : 5,
          }}
        >
          <Box display={'flex'} sx={{ flexDirection: "column" }}>
            <AuthorImageAndName node={props.play} />

            <Box sx={{ position: "relative", flexGrow: 1, marginTop: 1 }}>
              {/* Button to select play/exercise*/}
              <Box sx={{ position: "relative", flexGrow: 1 }}>
                <Tooltip title={state.operationMode === OperationMode.play ? t('PlayCard04') : t('ExerciseCard12')} enterDelay={1000}>
                  <Fab
                    color={state.operationMode === OperationMode.play ? "primary" : "secondary"}
                    size="small"
                    sx={{
                      position: 'absolute',
                      bottom: '40px',
                      right: '4px',
                      opacity: 0.7, // para añadir transparencia
                      transition: 'opacity 0.3s',
                      '&:hover': {
                        opacity: 0.9,
                        backgroundColor: '#00398F',
                        color: 'white',
                      },
                    }}
                    onClick={() => {
                      if (state.operationMode === OperationMode.play) {
                        // play
                        // Check play is not already in playbook
                        if (state.curPlaybook.relationships.field_plays?.data.map((x: JSONAPITypeId) => x.id).includes(props.play.id)) {
                          dispatch(getActionSetConfirm(t('PlayCard05')));
                          return
                        }
                        const selectedPlays: JSONAPITypeId[] = [...state.curPlaybook.relationships.field_plays?.data || [], getRelationshipsDataElementFor(props.play)]
                        const action: ActionSetPlaybook = { type: 'setPlaybook', playbook: getNodeWithUpdatedRelationship(state.curPlaybook, 'field_plays', selectedPlays) }
                        dispatch(action);
                        setOpenSnackbarMessage(true);
                        setTimeout(() => { setOpenSnackbarMessage(false); }, 2000)
                      } else {
                        // exercise
                        setOpenDialogExerciseDurationAndFocus(true)
                      }
                    }}
                  >
                    <AddRoundedIcon />
                  </Fab>
                </Tooltip>

                {/* Show play */}
                {
                  state.operationMode === OperationMode.play &&
                  <PlayIllustrate
                    key={props.play.id}
                    play={props.play}
                    playDetails={playDetails ? playDetails : PLAYDETAILSINIT} />
                }
                {/* Show exercise, no svg file */}
                {
                  state.operationMode === OperationMode.exercise && !playDetails &&
                  <Box position='relative'>
                    <CardMedia
                      sx={{ display: 'block', maxHeight: '300px', objectFit: 'contain', margin: 'auto', borderRadius: 2 }}
                      component='img'
                      src={exerciseImage(state, fileIDImage, props.play.id)}
                      // If image is not jet on CDN then retry in a 5s.
                      onError={() => {
                        if (state.exerciseIDLastCRUD === props.play.id) {
                          if (retries < 6)
                            setTimeout(() => {
                              setRetries(retries + 1)
                              log.info(`reload image: (${retries}) ${exerciseImage(state, fileIDImage)}`);
                              const src = exerciseImage(state, fileIDImage, props.play.id);
                              if (imageRef && imageRef.current && imageRef.current.src)
                                imageRef.current.src = src;
                            }, 5000)
                          else {
                            log.error(`Error reload image: (${retries}) ${exerciseImage(state, fileIDImage)}`);
                            setRetries(0)
                          }
                        }
                      }}
                      alt={`${state.exerciseIDLastCRUD === props.play.id ? t('ExerciseCard05') : t('ExerciseCard06')}`}
                      ref={imageRef}
                      onClick={() => setOpenDialogExerciseDetails(true)}
                    />
                    {/* reposition video play icon 36px becuase it fits well with icon size of 24x24, ie PlayCircleOutlineIcon */}
                    {/* <PlayCircleOutlineIcon sx={{ display: fileIDVideo ? 'block' : 'none', position: 'relative', top: '-36px', left: `${videoPlayIconLeft}px` }} /> */}
                    <PlayCircleOutlineIcon sx={{ display: fileIDVideo ? 'block' : 'none', position: 'absolute', bottom: '4px', left: '4px' }} />
                  </Box>
                }
                {/* Show exercise, created exercise svg */}
                {
                  state.operationMode === OperationMode.exercise && playDetails &&
                  <PlayIllustrate
                    key={props.play.id}
                    play={props.play}
                    playDetails={playDetails}
                  />
                }
              </Box>
            </Box>

            <Box sx={{ display: "flex" }}>

              {/* Edit icon */}
              {props.play.relationships.uid.data.id === state.user.data.id && (
                <IconButton size="small" onClick={() => {
                  // dispatch({ type: 'setPlay', play: play });
                  navigate(state.operationMode === OperationMode.play ? "/createplay" : "/exercisecreate", { state: { play: props.play } })
                }}>
                  <EditIcon />
                </IconButton>
              )}

              {/* Favorite icon */}
              <Favorite node={props.play} field='field_favorite_plays' />

              {/* Play/exercise name */}
              <Typography
                sx={{
                  fontSize: { xs: 11, sm: 14 },
                  lineHeight: 1,
                  fontWeight: "bold",
                  marginTop: "auto",
                  marginBottom: "auto",
                  width: 1,
                }}
              >
                {props.play.attributes.title}
              </Typography>

              {
                state.operationMode === OperationMode.play &&
                <Suspense fallback={<div>Loading...</div>}>
                  <GeneratePDFPlay play={props.play} playDetails={playDetails} />
                </Suspense>
              }

              {/* More icon to show concepts if there are any */}
              {
                state.operationMode === OperationMode.exercise && props.play.relationships.field_concepts.data.length > 0 && (
                  <Tooltip title={t('ExerciseCard11')}>
                    <IconButton size='small' onClick={() => setShowConcepts(!showConcepts)}> {/* Cambiar a showConcepts */}
                      {showConcepts ? <TipsAndUpdatesIcon fontSize='small' color='primary' /> : <TipsAndUpdatesOutlinedIcon fontSize='small' />}
                    </IconButton>
                  </Tooltip>
                )
              }


              {/* Download button. Native app - get message. Otherwise, download play */}
              {
                state.operationMode === OperationMode.play &&
                <Tooltip title={t('PlayCard07')}>
                  <IconButton
                    size="small"
                    onClick={() => {
                      if (state.nativeApp) {
                        dispatch(getActionSetConfirm(t('PlayCard06')))
                      } else {
                        setOpenDialogPlayAnimation(true)
                      }
                    }}>
                    <ArrowCircleDownRoundedIcon />
                  </IconButton>
                </Tooltip>
              }
            </Box>

            {/* toggle show description if description is very long */}
            {!state.portrait && description && (
              <>
                <Typography sx={{ fontSize: { xs: 11, sm: 13 }, lineHeight: 1.4, whiteSpace: 'pre-wrap' }} >
                  {showPlayDescription
                    ? description
                    : description.substring(0, 150) + (description.length > 150 ? '...' : '')
                  }
                </Typography>
                {description.length > 150 && (
                  <Typography
                    onClick={() => setShowPlayDescription(!showPlayDescription)}
                    sx={{
                      textTransform: 'none',
                      paddingTop: 1,
                      fontSize: '12px',
                      cursor: 'pointer',
                      color: theme.palette.text.secondary,
                      textDecoration: 'underline',
                    }}
                  >
                    {showPlayDescription ? t('ExerciseCard10') : t('ExerciseCard09')}
                  </Typography>
                )}
              </>
            )}
            {/* Concept. Show buttons for each exercise concept */}
            {showConcepts && (
              <Box display="flex" justifyContent="center" flexWrap="wrap" gap={0.5} marginTop={1}>
                {
                  props.play.relationships.field_concepts.data.map((conceptRef: JSONAPITypeId) => (state.allConcepts.find(x => x.id === conceptRef.id))).map((concept: DrupalEntity, index: number) => (
                    <Fragment key={index}>
                      <Tooltip
                        title={newConcept(concept) ? `${t('ExerciseCard07')} "${concept.attributes.title}" ${t('ExerciseCard08')}` : ''}
                        enterDelay={500}
                      >
                        <Button
                          variant='text'
                          sx={{
                            fontSize: { xs: 9, sm: 10 },
                            textTransform: 'none',
                            fontWeight: 'bold',
                            borderRadius: '20px',
                            cursor: 'pointer',
                            padding: '1px 4px',
                            color: theme.palette.text.primary,
                            border: '1px solid lightgray',
                          }}
                          onClick={() => {
                            setConceptFileID(concept.relationships.field_concept_video.data.id)
                            setConcept(concept.attributes.title)
                            log.info('DialogShowVideo() - ' + concept.attributes.title);
                          }}
                        >
                          {concept.attributes.title}
                        </Button>
                      </Tooltip>
                    </Fragment>
                  ))
                }
              </Box>
            )}
          </Box>
        </Paper >
      }

      {/* Show selected play or exercise */}
      {
        props.index !== undefined && props.play &&
        <Box mt={props.index === 0 ? 0 : 1}>

          {/* 1. line. index, title */}
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
          >

            <Typography sx={{ fontSize: 14, fontWeight: "bold", color: "#00398F", marginRight: 1 }}>
              {`${props.index + 1}.`}
            </Typography>

            <Typography sx={{ fontSize: 12, fontWeight: "bold", color: '#00398F' }}>
              {props.play.attributes.title || t("PlayCard02")}
            </Typography>

            {
              // state.operationMode === OperationMode.play &&
              // <IconButton
              //   onClick={() => setShowPlayOnePhase(!showPlayOnePhase)}
              //   sx={{ marginLeft: 1 }}
              // >
              //   {showPlayOnePhase ? (
              //     <VisibilityOffIcon fontSize="small" sx={{ color: '#00398F' }} />
              //   ) : (
              //     <VisibilityIcon fontSize="small" sx={{ color: '#00398F' }} />
              //   )}
              // </IconButton>
            }

            {/* Start and stop time for practice or duration for preplanned practice */}
            {
              state.operationMode === OperationMode.exercise &&
              < Typography sx={{ fontSize: 14, fontWeight: 'bold', fontFamily: 'PT sans, sans-serif', color: theme.palette.text.primary }} textAlign={'right'}>
                {props.startAndStopTime}
              </Typography>
            }
          </Stack>

          {/* 2. line. image, call out name of play or duration/note, icons to delete, move up, move down  */}
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
          >
            {/* svg for play */}
            {
              state.operationMode === OperationMode.play &&
              <PlayIllustrate key={props.play.id} play={props.play} playDetails={playDetails} />
            }
            {/* webp for exercise */}
            {
              state.operationMode === OperationMode.exercise && !props.play.attributes.field_exercise_details &&
              <img
                src={exerciseImage(state, fileIDImage, props.play.id)}
                height="100" // Ajusta esto según sea necesario
                style={{ display: 'flex', borderRadius: '4px', objectFit: 'contain', height: '100px' }}
                alt="Imagen del Ejercicio"
              />
            }
            {/* svg for exercise */}
            {
              state.operationMode === OperationMode.exercise && playDetails &&
              <PlayIllustrate key={props.play.id} play={props.play} playDetails={playDetails} />}

            {/* play call out name */}
            {
              state.operationMode === OperationMode.play &&
              <TextField
                key={props.index}
                size="small"
                id="outlined-basic"
                label={t('CreatePlay03')}
                variant="outlined"
                value={field_play_name_call_out()}
                onChange={(e) => {
                  const field_play_name_call_out: { [key: string]: string } = {};
                  field_play_name_call_out[props.play.id!] = e.target.value;
                  const action: ActionSetPlaybook = {
                    type: "setPlaybook",
                    playbook: {
                      ...state.curPlaybook,
                      attributes: {
                        ...state.curPlaybook.attributes,
                        field_play_name_call_out: JSON.stringify({
                          ...JSON.parse(
                            state.curPlaybook.attributes.field_play_name_call_out || "{}"
                          ),
                          ...field_play_name_call_out,
                        }),
                      },
                    },
                  };
                  dispatch(action);
                }}
              />
            }

            {/* Duration and focus */}
            {
              state.operationMode === OperationMode.exercise &&
              <Box sx={{ display: 'flex', flexDirection: 'column' }} margin={0}>
                {/* Duration */}
                <FormControl>
                  <InputLabel id="duration-label"></InputLabel>
                  <Select
                    labelId="duration-label"
                    id="duration"
                    value={state.curPractice.selectedExercises[props.index].durationMinutes}
                    onChange={(e) => {
                      dispatchSetPractice(state.curPractice.selectedExercises.toSpliced(props.index!, 1, {
                        ...state.curPractice.selectedExercises[props.index!],
                        durationMinutes: Number(e.target.value)
                      }))
                    }}
                    input={<Input id="duration" />}
                    style={{ fontFamily: 'PT sans, sans-serif' }}
                    renderValue={(selected) => `${selected} ${selected === 1 ? t('ExerciseCardSelected01') : t('ExerciseCardSelected02')}`}
                  >
                    {Array.from({ length: 31 }, (_, i) => (
                      <MenuItem key={i} value={i}>
                        {i}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {/* Focus */}
                <TextField
                  variant='standard'
                  color='primary'
                  margin="dense"
                  id="focus"
                  label={t('Generel08')}
                  size="small"
                  multiline
                  sx={{ fontFamily: 'PT sans, sans-serif' }} // Use sx prop instead of style
                  onChange={(e) => {
                    dispatchSetPractice(state.curPractice.selectedExercises.toSpliced(props.index!, 1, {
                      ...state.curPractice.selectedExercises[props.index!],
                      coachNote: e.target.value
                    }))
                  }}
                  value={state.curPractice.selectedExercises[props.index].coachNote}
                  InputProps={{
                    inputProps: { maxLength: 300 },
                    sx: { fontFamily: 'PT sans, sans-serif' } // Ensure fontFamily applies to the input element
                  }}
                />
              </Box>
            }

            {/* Delete icon, arrow up icon and arrow down icon */}
            <Stack
              direction={'column'}
            >
              {/* Delete icon - delete play/exercise */}
              <IconButton
                onClick={() => {
                  if (state.operationMode === OperationMode.play) {
                    const action: ActionSetPlaybook = {
                      type: "setPlaybook",
                      playbook: {
                        ...state.curPlaybook,
                        relationships: {
                          ...state.curPlaybook.relationships,
                          field_plays: {
                            data: state.curPlaybook.relationships.field_plays.data.toSpliced(props.index, 1)
                          }
                        }
                      }
                    }
                    dispatch(action);
                  } else {
                    dispatchSetPractice(state.curPractice.selectedExercises.toSpliced(props.index!, 1))
                  }
                }}
              >
                <DeleteOutlineOutlinedIcon fontSize="small" sx={{ color: "grey" }} />
              </IconButton>

              {/* Arrow up */}
              {props.index !== 0 &&
                <IconButton
                  onClick={() => {
                    if (state.operationMode === OperationMode.play) {
                      const data = state.curPlaybook.relationships.field_plays.data
                      const action: ActionSetPlaybook = {
                        type: "setPlaybook",
                        playbook: {
                          ...state.curPlaybook,
                          relationships: {
                            ...state.curPlaybook.relationships,
                            field_plays: {
                              data: data.toSpliced(props.index! - 1, 2, data[props.index!], data[props.index! - 1])
                            }
                          }
                        }
                      }
                      dispatch(action);
                    } else {
                      const data = state.curPractice.selectedExercises
                      dispatchSetPractice(data.toSpliced(props.index! - 1, 2, data[props.index!], data[props.index! - 1]))
                    }
                  }}
                >
                  <ArrowCircleUpRoundedIcon fontSize="small" sx={{ color: "grey" }} />
                </IconButton>
              }

              {/* Arrow down, dont show down arrow on last element in list */}
              {props.index !== (state.operationMode === OperationMode.play
                ? state.curPlaybook.relationships.field_plays.data.length - 1
                : state.curPractice.selectedExercises.length - 1) &&
                <IconButton
                  onClick={() => {
                    if (state.operationMode === OperationMode.play) {
                      const data = state.curPlaybook.relationships.field_plays.data
                      const action: ActionSetPlaybook = {
                        type: "setPlaybook",
                        playbook: {
                          ...state.curPlaybook,
                          relationships: {
                            ...state.curPlaybook.relationships,
                            field_plays: {
                              data: data.toSpliced(props.index, 2, data[props.index! + 1], data[props.index!])
                            }
                          }
                        }
                      }
                      dispatch(action);
                    } else {
                      const data = state.curPractice.selectedExercises
                      dispatchSetPractice(data.toSpliced(props.index!, 2, data[props.index! + 1], data[props.index!]))
                    }
                  }}
                >
                  <ArrowCircleDownRoundedIcon
                    fontSize="small"
                    sx={{ color: "grey" }}
                  />
                </IconButton>
              }

            </Stack>
          </Stack>
        </Box>
      }

      {/* ToDo. Please find ex where user does not have access to play/exercise - do we have such a case? */}

      {/* Play/exercise IS selected for a playbook and user has NO access to play/exercise */}
      {
        props.index !== undefined && !props.play && (
          <Box mt={props.index === 0 ? 0 : 1}>
            <Grid2
              sx={{
                backgroundColor: "#e1f5fe",
                width: "100%",
                height: '35px',
                borderRadius: 1,
                border: `1px solid ${theme.palette.divider}`,
                padding: 1,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box display="flex" alignItems="center">
                <Typography sx={{ fontSize: 14, fontWeight: "bold", color: '#00398F' }}>
                  {`${props.index + 1}. ${t("PlayCard02")}`}
                </Typography>
              </Box>

              <Stack direction="row" spacing={0} alignItems="center">

                <IconButton
                  onClick={() => {
                    if (state.operationMode === OperationMode.play) {
                      const selectedPlays: JSONAPITypeId[] = state.curPlaybook.relationships.field_plays.data.filter((_item: JSONAPITypeId, i: number) => i !== props.index)
                      const action: ActionSetPlaybook = { type: 'setPlaybook', playbook: getNodeWithUpdatedRelationship(state.curPlaybook, 'field_plays', selectedPlays) }
                      dispatch(action);
                    } else {
                      const action: ActionSetPractice = { type: 'setPractice', practice: { ...state.curPractice, selectedExercises: state.curPractice.selectedExercises.toSpliced(props.index!, 1) } }
                      dispatch(action);
                    }
                  }}
                >
                  <DeleteOutlineOutlinedIcon fontSize="small" sx={{ color: "grey" }} />
                </IconButton>

              </Stack>
            </Grid2>

            {/* {showPlayOnePhase && (
              <Grid2 container alignItems="flex-start" padding={1} sx={{ flexGrow: 1 }}>
                <Grid2 sx={{ flexGrow: 1 }}
                >
                  <PlayIllustrate key={0} play={DRUPALENTITYINIT} playDetails={playDetails} />
                </Grid2>
              </Grid2>
            )} */}
          </Box>
        )
      }

      {/* Open DialogPlayAnimation when user clicks download button */}
      <DialogPlayAnimation
        play={props.play || DRUPALENTITYINIT}
        isOpen={openDialogPlayAnimation}
        onClose={() => { setOpenDialogPlayAnimation(false) }}
        frames={playDetails ? playDetails.frames : PLAYDETAILSINIT.frames}
        courtType={playDetails ? playDetails.courtType : 'halfcourt'}
        autoDownload={true}
      />

      <SnackbarMessages
        message={t('PlayCard03')}
        open={openSnackbarMessage}
        icon={<PlaylistAddCheckCircleRoundedIcon fontSize="small" sx={{ color: '#00398F' }} />}
        color={'#00398F'}
        landscapeVerticalPosition='bottom'
        landscapeHorizontalPosition='right'
      />

      <DialogExerciseDetails
        open={openDialogExerciseDetails}
        onClose={() => setOpenDialogExerciseDetails(false)}
        exercise={props.play || DRUPALENTITYINIT}
        addOption={true}
      />

      <DialogExerciseDurationAndFocus
        open={openDialogExerciseDurationAndFocus}
        onClose={() => setOpenDialogExerciseDurationAndFocus(false)}
        exercise={props.play}
      />

      <DialogShowVideo
        open={conceptFileID !== ''}
        onClose={() => setConceptFileID('')}
        fileIDVideo={conceptFileID}
        concept={concept}
      />
    </Fragment >
  );
}