import { useCallback, useContext, useEffect, useState } from "react";
import log from "../misc/Logger";
import { useTranslation } from "react-i18next";
import { Context } from "../App";
import { CRUD, DrupalEntity, JSONAPITypeId, TypeContext } from "../misc/Types";
import { DataGrid, GRID_CHECKBOX_SELECTION_COL_DEF, GridActionsColDef, GridColDef, GridRowModel, GridRowSelectionModel } from "@mui/x-data-grid";
import { Box, Button, Divider, Typography, useTheme } from "@mui/material";
import { betterUserMessage, createNode, deleteActionForDataGrid, getActionSetConfirm, getAllContentEntities, icbControllerGenerel02, nodeCRUD, randomId, updateNode } from "../misc/Functions";
import AddIcon from '@mui/icons-material/Add';

interface PropsMyPlayers {
    // onClose?: (actOnSelected: boolean) => void, // call with true if dialog action is to be performed. Call with false if dialog action is cancelled
    onClose: () => void,
    team: DrupalEntity,
}

/*
Manage players, create, update and delete. If props team is given then there is a checkbox for each
player to manage if the player is part of the given team.
*/
export default function MyPlayers(props: PropsMyPlayers) {
    const { state, dispatch } = useContext(Context) as TypeContext;
    const { t } = useTranslation();
    log.debug('MyPlayers');
    const theme = useTheme();

    // find all rows
    function allRows() {
        const allRowsLocal = state.allPlayers.filter(x => x.relationships.uid.data.id === state.user.data.id).map((item) => ({
            id: item.id,
            field_player_first_name: item.attributes.field_player_first_name,
            field_player_last_name: item.attributes.field_player_last_name,
            field_player_date_of_birth: new Date(item.attributes.field_player_date_of_birth),
        }))
        return allRowsLocal
    }

    const [disabled, setDisabled] = useState(false)
    const [rows, setRows] = useState(allRows())
    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);

    useEffect(() => {
        // Find list of players on the team that has just been updated because checkbox selection has been changed
        setRowSelectionModel(state.allTeams.find(x => x.id === props.team.id)!.relationships.field_players.data.map((x: JSONAPITypeId) => x.id) || [])
    }, [state.allTeams])

    // data columns
    const columns: (GridColDef | GridActionsColDef)[] = [
        { ...GRID_CHECKBOX_SELECTION_COL_DEF, width: 10 },
        { field: 'id', headerName: 'ID', width: 10, },
        { field: 'field_player_first_name', headerName: t('MyPlayers04'), flex: 1, editable: true, },
        { field: 'field_player_last_name', headerName: t('MyPlayers05'), flex: 1, editable: true, },
        { field: 'field_player_date_of_birth', headerName: t('MyPlayers06'), flex: 1, editable: true, type: 'date' },
        deleteActionForDataGrid(handleDelete)
    ]

    // see https://mui.com/x/react-data-grid/editing/ for mutation
    const useMutation = (newRow: GridRowModel) => {
        const player = {
            field_player_first_name: newRow.field_player_first_name.trim(),
            field_player_last_name: newRow.field_player_last_name.trim(),
            field_player_date_of_birth: newRow.field_player_date_of_birth.toISOString().slice(0, -5) + 'Z', // javascript date to drupal date
        }
        if (state.allPlayers.find(x => x.id === newRow.id)) {
            return updateNode(state, dispatch, 'player', { ...player, id: newRow.id }, state.allPlayers.find(x => x.id === newRow.id)?.attributes.drupal_internal__nid)
        } else
            return createNode(state, dispatch, 'player', player)
    };

    const mutateRow = (newRow: GridRowModel) => useMutation(newRow);

    const processRowUpdate = useCallback(
        async (newRow: GridRowModel) => {
            if (newRow.field_player_first_name.trim() === '' || newRow.field_player_last_name.trim() === '')
                throw new Error(t('MyPlayers07'))
            const response = await mutateRow(newRow);
            if (response.id) // if we don't get an id then the updateNode/createNode failed
                return { ...newRow, id: response.id, field_player_date_of_birth: new Date(response.field_player_date_of_birth) }; // covert to DataObject as needed by MUI DataGrid
            else {
                throw new Error(response.message)
            }
        },
        [mutateRow],
    );

    // Delete player
    function handleDelete(id: string) {
        dispatch(getActionSetConfirm(t('MyPlayers01'), '', () => {
            if (disabled)
                return
            setDisabled(true)
            // delete player from teams, practice attendance lists and players. Do this as back end job!
            icbControllerGenerel02(state, {
                opr: "delete_player",
                playerID: id
            })
                .then((resp) => {
                    if (resp.ok) {
                        getAllContentEntities(state, dispatch, 'node--player')
                        getAllContentEntities(state, dispatch, 'node--practice')
                        getAllContentEntities(state, dispatch, 'node--team')
                    }
                })
        }))
    }

    function handleAddRow() {
        // dont allow new row if we already new empty row
        if (rows.find(x => x.field_player_first_name === ''))
            return;
        setRows([...rows, {
            id: randomId(),
            field_player_first_name: '',
            field_player_last_name: '',
            field_player_date_of_birth: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
        }]);
    };

    return (
        <Box sx={{ width: '90%', maxWidth: '700px', margin: 'auto', paddingTop: 2 }}>
            <Typography paddingBottom={2} sx={{ fontFamily: 'PT Sans, sans-serif', color: theme.palette.primary.main, fontSize: { xs: '20px', sm: '25px' }, fontWeight: 'bold' }}>
                {/* {t('MyTeams02')} */}
                {props.team.attributes.title}
            </Typography>
            <Divider sx={{ marginBottom: 3 }} />

            {/* New player button */}
            <Button
                variant="outlined"
                startIcon={<AddIcon />}
                size="small"
                onClick={handleAddRow}
                sx={{ textTransform: 'none' }} // Eliminado marginTop para alinear verticalmente
            >
                {t('MyPlayers02')}
            </Button>

            <DataGrid
                sx={{
                    marginTop: 3,
                    marginBottom: '350px',
                    '& .MuiInputBase-input': {
                        fontSize: '16px', // we prevent autozoom of the row when editing
                    }
                }}
                columns={columns}
                rows={rows}
                columnVisibilityModel={{ id: false }}
                disableColumnMenu
                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={async (err) => {
                    // this could happen if same user with two clients create team with same name
                    const msg = await betterUserMessage(err.message)
                    dispatch(getActionSetConfirm(msg));
                    log.info(msg)
                }}
                rowHeight={30}
                checkboxSelection={props.team.id !== undefined}
                editMode="row"
                // set and follow selections in the DataGrid
                rowSelectionModel={rowSelectionModel}
                onRowSelectionModelChange={async (newRowSelectionModel) => {
                    // only handle player relation to team if we have team
                    if (!props.team.id)
                        return
                    // Update team players on the team
                    const newPlayerList = state.allPlayers.filter(x => newRowSelectionModel.includes(x.id!))
                    const teamNode = {
                        id: props.team?.id,
                        type: 'node--team',
                        relationships: {
                            field_players: {
                                data: newPlayerList.map(x => { return { type: 'node--player', id: x.id } })
                            }
                        }
                    }
                    const resp = await nodeCRUD(state, dispatch, CRUD.Update, teamNode)
                    if (!resp.data) {
                        dispatch(getActionSetConfirm(resp));
                        return
                    }
                }}
                disableRowSelectionOnClick // don't change checkbox when click on row, ie. when try to edit value in row
            />
        </Box >
    )
}