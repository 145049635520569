import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import SportsBasketballRoundedIcon from '@mui/icons-material/SportsBasketballRounded';
import { Box, darken, Grid, Modal, Typography, useTheme } from '@mui/material';
import { TypeContext } from '../misc/Types';
import { Context } from '../App';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useLocation
import { goHome } from '../misc/Functions';
import log from "../misc/Logger";
import { useTranslation } from 'react-i18next';
import { AddCircle } from '@mui/icons-material';
import { Fragment, useContext, useState } from 'react';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SportsBasketballOutlinedIcon from '@mui/icons-material/SportsBasketballOutlined';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import SportsBasketballIcon from '@mui/icons-material/SportsBasketball';

export default function BottomAppBar() {
  const { state, dispatch } = useContext(Context) as TypeContext;
  const navigate = useNavigate();
  const { t } = useTranslation();
  log.debug('BottomAppBar');
  const location = useLocation(); // Use the useLocation hook
  const theme = useTheme();

  const [openModal, setOpenModal] = useState(false);


  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleCreatePlay = () => {
    // Lógica para crear Play
    handleCloseModal();
    navigate('/createplay');
  };

  const handleCreateDrill = () => {
    // Lógica para crear Drill
    handleCloseModal();
    navigate('/exercisecreate');
  };

  // Function to determine if a tab is selected based on the route
  const isSelected = (path: string) => {
    return location.pathname === path;
  };

  return (
    <Fragment >
      <CssBaseline />
      <AppBar position="fixed" sx={{
        top: 'auto', bottom: 0, paddingBottom: state.nativeApp ? 3 : 0, backgroundColor: theme.palette.action.disabled,
        backdropFilter: 'blur(4px)',
      }}>
        <Toolbar style={{ justifyContent: 'space-between', marginLeft: 8, marginRight: 8 }}>

          {/* Home Tab */}
          <div style={{ textAlign: 'center', color: isSelected('/home') ? '#DD6F20' : theme.palette.text.primary }} // Conditional styling
            onClick={() => { goHome(dispatch, navigate) }}
          >
            {isSelected('/home') ? <HomeRoundedIcon /> : <HomeOutlinedIcon />}
            <Typography
              variant="body2"
              style={{
                fontSize: '12px',
                marginTop: -6,
                padding: '0',
              }}>
              {t('SmallScreenICBAppBar00')}
            </Typography>
          </div>

          {/* Drills Tab */}
          <div style={{ textAlign: 'center', color: ['/', '/icbdrills'].includes(location.pathname) ? '#DD6F20' : theme.palette.text.primary }} // Conditional styling
            onClick={() => {
              dispatch({ type: 'showPracticeProgram', show: false });
              navigate('/icbdrills');
            }}
          >
            {['/', '/icbdrills'].includes(location.pathname) ? <SportsBasketballRoundedIcon sx={{ color: '#DD6F20' }} /> : <SportsBasketballOutlinedIcon />}
            <Typography variant="body2" style={{ fontSize: '12px', marginTop: -6, padding: '0', color: ['/icbdrills'].includes(location.pathname) ? '#DD6F20' : theme.palette.text.primary }}>
              {t('SmallScreenICBAppBar01')}
            </Typography>
          </div>

          {/* Plays */}
          <div
            style={{ textAlign: 'center', color: isSelected('/icbplays') ? '#DD6F20' : theme.palette.text.primary }}
            onClick={() => { 
              dispatch({ type: 'showPracticeProgram', show: false });
              navigate('/icbplays');
            }}
          >
            {/* {isSelected('/icbplays') ? <img src={PlaysIconOrange} alt="Prácticas" style={{ width: '24px', height: '24px' }} /> : <img src={PlaysIcon} alt="Prácticas" style={{ width: '24px', height: '24px' }} />} */}
            {isSelected('/icbplays') ? (
              <img
                src={'/images/playbuilder/PlaysIconOrange.svg'}
                alt="Prácticas"
                style={{ width: '24px', height: '24px' }}
              />
            ) : (
              <img
                src={theme.palette.mode === 'dark' ? '/images/playbuilder/PlaysIconWhite.svg' : '/images/playbuilder/PlaysIcon.svg'}
                alt="Prácticas"
                style={{ width: '24px', height: '24px' }}
              />
            )}
            <Typography variant="body2" style={{ fontSize: '12px', marginTop: -6, padding: '0' }}>
              {t('SmallScreenICBAppBar04')}
            </Typography>
          </div>

          {/* Create drill */}
          <div style={{
            textAlign: 'center',
            color: isSelected('/exercisecreate') ? '#DD6F20' :
              (isSelected('/createplay') && theme.palette.mode !== 'dark') ? '#15468f' :
                (theme.palette.mode === 'dark' && !isSelected('/createplay')) ? theme.palette.primary.main :
                  (theme.palette.mode === 'light' && !isSelected('/createplay')) ? '#000000' :
                    '#DD6F20'
          }}
            onClick={handleOpenModal}>
            {isSelected('/exercisecreate') || isSelected('/createplay') ? <AddCircle /> : <AddCircleOutlineRoundedIcon />}
            <Typography variant="body2" style={{ fontSize: '12px', marginTop: -6, padding: '0' }}>
              {t('SmallScreenICBAppBar03')}
            </Typography>
          </div>
        </Toolbar>
      </AppBar>

      {/* Modal to create a drill or a play */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="crear-modal-titulo"
        aria-describedby="crear-modal-descripcion"
      >
        <Box
          sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: 2,
            boxShadow: 0,
            p: 0,
          }}
        >

          <Grid container justifyContent="center">

            {/* Create Drill */}
            <Grid item xs={5} textAlign="center" onClick={handleCreateDrill} style={{ cursor: 'pointer' }}>
              <Box
                sx={{
                  borderRadius: 2,
                  padding: 2,
                  width: '100%',
                  backgroundColor: '#fff3e0',
                  '&:hover': {
                    backgroundColor: darken('#fff3e0', 0.1),
                  },
                }}
              >
                <Typography gutterBottom sx={{ color: '#DD6F20', fontWeight: 'bold' }}>
                  {t('DrawerLandscape04')}
                </Typography>
                <SportsBasketballIcon fontSize="large" sx={{ color: '#DD6F20' }} />
              </Box>
            </Grid>

            {/* Create Play */}
            <Grid item xs={5} textAlign="center" onClick={handleCreatePlay} style={{ cursor: 'pointer' }}>
              <Box
                sx={{
                  borderRadius: 2,
                  width: '100%',
                  padding: 2,
                  marginLeft: 1,
                  backgroundColor: '#e1f5fe',
                  '&:hover': {
                    backgroundColor: darken('#e1f5fe', 0.1),
                  },
                }}
              >
                <Typography gutterBottom sx={{ color: '#15468F', fontWeight: 'bold' }}>
                  {t('CreatePlay07')}
                </Typography>
                <img src={'/images/playbuilder/PlaysIconBlue.svg'} alt="Prácticas" style={{ width: '35px', height: '35px' }} />
              </Box>
            </Grid>

          </Grid>
        </Box>
      </Modal>
    </Fragment>
  )
}