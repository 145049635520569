import { Box, Button, Divider, TextField, Typography, Grid } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import log from "../misc/Logger";
import { Context } from '../App';
import { ActionSetPreplannedPractice, CRUD, DrupalEntity, PreplannedPractice, TeamplanAgeGroup, TeamplanDuration, TeamplanSkillLevel, TypeContext } from '../misc/Types';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useRef, useState } from 'react';
import { getActionSetConfirm, nodeCRUD, menuItemsForType, mapPreplannedPractice } from '../misc/Functions';
import { useTheme } from '@mui/material/styles';
import PracticeProgramSelectedExercises from './PracticeProgramSelectedExercises';
import { PREPLANNEDPRACTICEINIT } from '../misc/Constants';

export default function PreplannedPracticeForm() {
    const { state, dispatch } = useContext(Context) as TypeContext;
    const { t } = useTranslation();
    const theme = useTheme();
    log.debug('PreplannedPracticeForm');

    useEffect(() => {
        // use cleanup function to update global state when component unmounts
        return () => {
            // update global data/state
            const action: ActionSetPreplannedPractice = {
                type: 'setPreplannedPractice',
                preplannedPractice: {
                    ...formikValuesRef.current,
                    selectedExercises: curPreplannedPracticeSelectedExercisesRef.current,
                }
            }
            dispatch(action)
        };
    }, [])

    const buttonStyle = {
        textTransform: 'none',
        fontSize: '0.8rem',
        height: '25px',
        color: theme.palette.text.primary,
        border: '1px solid ' + theme.palette.divider,
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: theme.palette.action.selected,
        }
    }

    const [disabled, setDisabled] = useState(false)
    const [operation, setOperation] = useState('')

    const validationSchema = yup.object({
        note: yup
            .string(),
        teamplanAgeGroup: yup
            .string()
            .required(t('PreplannedPracticeForm00')),
        teamplanSkillLevel: yup
            .string()
            .required(t('PreplannedPracticeForm00')),
        teamplanDuration: yup
            .string()
            .required(t('PreplannedPracticeForm00')),
    });

    const formik = useFormik({
        initialValues: {
            note: state.curPreplannedPractice.note,
            teamplanAgeGroup: state.curPreplannedPractice.teamplanAgeGroup,
            teamplanSkillLevel: state.curPreplannedPractice.teamplanSkillLevel,
            teamplanDuration: state.curPreplannedPractice.teamplanDuration,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (disabled)
                return
            setDisabled(true)
            // set the practice draft we want to create/update
            const preplannedPractice: PreplannedPractice = {
                note: values.note,
                selectedExercises: state.curPreplannedPractice.selectedExercises.map(x => {
                    // save only drupal_internal__nid of selected exercise and not entire object from back end
                    const selectedExercise: any = { ...x }
                    selectedExercise['drupal_internal__nid'] = x.exercise.attributes.drupal_internal__nid
                    delete selectedExercise.exercise
                    return selectedExercise
                }),
                teamplanAgeGroup: values.teamplanAgeGroup,
                teamplanSkillLevel: values.teamplanSkillLevel,
                teamplanDuration: values.teamplanDuration,
            }

            if (state.curPreplannedPractice.selectedExercises.length === 0) {
                // Save when no exercises - makes no sense!
                dispatch(getActionSetConfirm(t('PracticeProgramForm12')));
                return
            }

            // check duration
            if (state.curPreplannedPractice.selectedExercises.map(x => x.durationMinutes).reduce((a, b) => a + b) !== parseInt(values.teamplanDuration.slice(1))) {
                dispatch(getActionSetConfirm(t('PreplannedPracticeForm01'), t('Okay00')));
                setDisabled(false)
                return
            }

            // dublicate check
            const newPreplannedPracticeStringified = JSON.stringify(preplannedPractice)
            const dublicate = state.allPreplannedPractices.find(x => newPreplannedPracticeStringified === JSON.stringify(mapPreplannedPractice(x)))
            if (dublicate) {
                dispatch(getActionSetConfirm(t('PreplannedPracticeForm07'), t('Okay00')));
                setDisabled(false)
                return
            }

            let node: DrupalEntity = {
                type: 'node--preplanned_practice',
                attributes: {
                    title: `${values.teamplanAgeGroup}, ${values.teamplanSkillLevel}, ${values.teamplanDuration}, ${values.note}`,
                    body: {
                        value: JSON.stringify(preplannedPractice)
                    }
                },
            }

            // create or update?
            let CRUDOperation: CRUD = CRUD.Create // assume create new practice draft
            if (state.curPreplannedPractice.uuid && operation === 'save') {
                CRUDOperation = CRUD.Update // no, update practice draft
                node['id'] = state.curPreplannedPractice.uuid
            }

            // update back end
            nodeCRUD(state, dispatch, CRUDOperation, node)
                .then((resp) => {
                    if (resp.data) {
                        // make sure global state is updated with update/new preplanned practice
                        const action: ActionSetPreplannedPractice = {
                            type: 'setPreplannedPractice', preplannedPractice: {
                                ...mapPreplannedPractice(resp.data),
                                uuid: resp.data.id
                            }
                        }
                        dispatch(action)
                    } else {
                        dispatch(getActionSetConfirm(resp));
                    }
                })
                .finally(() => {
                    setDisabled(false)
                })
        }
    });

    // // check and show message if we have a preplanned practice like the current one.
    // function dublicate(): boolean {
    //     const dublicateLocal = state.allPreplannedPractices
    //         .map(x => mapPreplannedPractice(x))
    //         .find((x: PreplannedPractice) => x.note === formik.values.note
    //             && x.teamplanAgeGroup === formik.values.teamplanAgeGroup
    //             && x.teamplanDuration === formik.values.teamplanDuration
    //             && x.teamplanSkillLevel === formik.values.teamplanSkillLevel
    //             // && JSON.stringify(x.selectedExercises) === JSON.stringify(state.curPreplannedPractice.selectedExercises)
    //         )
    //     if (dublicateLocal) {
    //         dispatch(getActionSetConfirm(t('PreplannedPracticeForm07'), t('Okay00')));
    //         setDisabled(false)
    //         return true
    //     }
    //     return false
    // }

    // Hold on to values as they change so we can update global state when component dismounts in useEffect return statement
    const formikValuesRef = useRef(formik.values);
    const curPreplannedPracticeSelectedExercisesRef = useRef(state.curPreplannedPractice.selectedExercises);
    useEffect(() => {
        formikValuesRef.current = formik.values
        curPreplannedPracticeSelectedExercisesRef.current = state.curPreplannedPractice.selectedExercises
    }, [formik.values, state.curPreplannedPractice.selectedExercises]);

    return (
        <Grid container direction="row" alignItems="stretch" style={{ marginBottom: 150 }} >
            {!state.portrait && (<Divider orientation="vertical" flexItem sx={{ minHeight: '100vh' }} />)}
            <Grid item xs margin={1}> {/* Wrap Card in a Grid item */}



                <Box sx={{
                    background: 'linear-gradient(to right, #e1f5fe, #a0d8ef)',
                    padding: 0.5,
                    borderRadius: 1,
                    border: '1px solid ' + theme.palette.divider,
                }}>
                    <Typography
                        textAlign={'center'}
                        sx={{
                            fontSize: { xs: 20, sm: 23 },
                            fontFamily: 'PT Sans, sans-serif',
                            fontWeight: 'bold',
                            color: '#15468f'
                        }}
                    >
                        {t('PreplannedPracticeForm02')}
                    </Typography>
                </Box>


                <form onSubmit={formik.handleSubmit}>

                    <Grid container>
                        {/* Note */}


                        {/* Age, skill and duration */}
                        <Grid item xs={12} container paddingTop={1} spacing={1}>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    fullWidth
                                    select
                                    id="teamplanAgeGroup"
                                    name="teamplanAgeGroup"
                                    label={t('PreplannedPractices06')}
                                    type="teamplanAgeGroup"
                                    value={formik.values.teamplanAgeGroup}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.teamplanAgeGroup && Boolean(formik.errors.teamplanAgeGroup)}
                                    helperText={formik.touched.teamplanAgeGroup && formik.errors.teamplanAgeGroup}
                                    size="small"
                                >
                                    {menuItemsForType(TeamplanAgeGroup)}
                                </TextField>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField
                                    fullWidth
                                    select
                                    id="teamplanSkillLevel"
                                    name="teamplanSkillLevel"
                                    label={t('PreplannedPractices07')}
                                    type="teamplanSkillLevel"
                                    value={formik.values.teamplanSkillLevel}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.teamplanSkillLevel && Boolean(formik.errors.teamplanSkillLevel)}
                                    helperText={formik.touched.teamplanSkillLevel && formik.errors.teamplanSkillLevel}
                                    size="small"
                                >
                                    {menuItemsForType(TeamplanSkillLevel)}
                                </TextField>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField
                                    fullWidth
                                    select
                                    id="teamplanDuration"
                                    name="teamplanDuration"
                                    label={t('PreplannedPractices08')}
                                    type="teamplanDuration"
                                    value={formik.values.teamplanDuration}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.teamplanDuration && Boolean(formik.errors.teamplanDuration)}
                                    helperText={formik.touched.teamplanDuration && formik.errors.teamplanDuration}
                                    size="small"
                                >
                                    {menuItemsForType(TeamplanDuration)}
                                </TextField>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="note"
                                name="note"
                                label={t('PreplannedPractices05')}
                                value={formik.values.note}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.note && Boolean(formik.errors.note)}
                                helperText={formik.touched.note && formik.errors.note}
                                size="small"
                                sx={{ marginTop: 1 }}
                                inputProps={{ maxLength: 45 }}
                            />
                        </Grid>
                    </Grid>

                    {/* note that button is not a submit button. call formik.submitForm() to submit form - we have to set
                state varialble to indicate what button was presse.
                we need button for 'save' and button for 'create new' If new preplanned practice then
                'save' acts as 'create new */}
                    <Grid paddingTop={1} paddingBottom={0} display={'flex'} justifyContent={'space-evenly'}>

                        {/* save */}
                        <Button
                            sx={buttonStyle}
                            onClick={() => {
                                dispatch(getActionSetConfirm(state.curPreplannedPractice.uuid ? t('PreplannedPracticeForm08') : t('PreplannedPracticeForm11'), t('Yes00'), () => {
                                    setOperation('save');
                                    formik.submitForm()
                                }))
                            }}
                        >
                            {/* {state.curPreplannedPractice.uuid ? t('PreplannedPracticeForm05') : t('PreplannedPracticeForm03')} */}
                            {t('PreplannedPracticeForm03')}
                        </Button>

                        {/* create new */}
                        {
                            state.curPreplannedPractice.uuid &&
                            <Button
                                sx={buttonStyle}
                                onClick={() => {
                                    dispatch(getActionSetConfirm(t('PreplannedPracticeForm09'), t('Yes00'), () => {
                                        setOperation('create');
                                        formik.submitForm()
                                    }))
                                }}
                            >
                                {t('PreplannedPracticeForm04')}
                            </Button>
                        }

                        {/* clear */}
                        <Button
                            sx={buttonStyle}
                            onClick={() => {
                                dispatch(getActionSetConfirm(t('PreplannedPracticeForm10'), t('Yes00'), () => {
                                    const action: ActionSetPreplannedPractice = { type: 'setPreplannedPractice', preplannedPractice: PREPLANNEDPRACTICEINIT }
                                    dispatch(action)
                                    setOperation('clear');
                                    formik.resetForm({
                                        values: {
                                            note: '',
                                            teamplanAgeGroup: TeamplanAgeGroup.u8,
                                            teamplanDuration: TeamplanDuration.m60,
                                            teamplanSkillLevel: TeamplanSkillLevel.beginner,
                                        }
                                    })
                                }))
                            }}>
                            {t('PreplannedPracticeForm06')}
                        </Button>
                    </Grid>

                    <PracticeProgramSelectedExercises />

                </form>
            </Grid>
        </Grid>
    );
}
