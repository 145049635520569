import { useContext, useEffect, useRef, useState } from "react";
import log from "../misc/Logger";
import { useTranslation } from "react-i18next";
import { Context } from "../App";
import { ActionSetState, CRUD, DrupalEntity, JSONAPITypeId, TypeContext, UserType } from "../misc/Types";
import { Box, Button, Divider, styled, Typography, useTheme } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridActionsColDef, GridColDef, GridRowModel, GridRowParams, } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { BACKEND } from "../misc/Constants";
import mime from 'mime';
import { getActionSetConfirm, nodeCRUD } from "../misc/Functions";
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import ArrowUpward from '@mui/icons-material/ArrowUpward';

export default function ClubDocuments() {
    const { state, dispatch } = useContext(Context) as TypeContext;
    const { t } = useTranslation();
    log.debug('ClubDocuments');
    const navigate = useNavigate();
    const theme = useTheme();

    const [rows, setRows] = useState([]) // get rows from back end initailly
    const [disabled, setDisabled] = useState(false)
    const clickTimeout = useRef<number | null>(null);

    const mimeTypeClubDocuments = ['application/pdf', 'text/plain']

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    // columns for all users
    const columns: (GridColDef | GridActionsColDef)[] = [
        { field: 'id', headerName: 'ID', width: 10 },
        { field: 'description', headerName: t('CRUDList09'), flex: 1, editable: state.user.data.attributes.field_user_type === UserType.clubadmin },
    ]

    // extra columns for clubadmins
    const columnsActions: (GridColDef | GridActionsColDef)[] = [
        {
            field: 'actions',
            type: 'actions',
            getActions: (params: GridRowParams) => [
                <GridActionsCellItem icon={<ArrowUpward />} onClick={() => handleMove(params.id.toString(), true)} label={t('CRUDList01')} showInMenu />,
                <GridActionsCellItem icon={<ArrowDownward />} onClick={() => handleMove(params.id.toString(), false)} label={t('CRUDList02')} showInMenu />,
                <GridActionsCellItem icon={<DeleteIcon />} onClick={() => { handleDelete(params.id.toString()) }} label={t('ClubDocuments05')} showInMenu />,
            ]
        }
    ]

    // get rows from back end initailly
    useEffect(() => {
        setRows(getRows())
        setDisabled(false)
    }, [state.user.data, state.allFiles])

    // get rows from back end
    function getRows() {
        let clubAdmin = state.user.data; // assume user is clubadmin
        if (state.user.data.attributes.field_user_type !== UserType.clubadmin)
            // no, user is not clubadmin - finder users clubadmin
            clubAdmin = state.allUsers.find(x => x.id === state.user.data.relationships.field_my_club_admin.data.id)!
        if (!clubAdmin.relationships.field_club_documents)
            return []
        return clubAdmin.relationships.field_club_documents.data
            .map((item: JSONAPITypeId) => ({
                id: item.id,
                description: item.meta!.description,
                // if we delete club docuemnt then we shortly have a case where the user refers to a club document that has been deleted. Protect agaist that case
                url: state.allFiles.find(x => x.id === item.id) ? state.allFiles.find(x => x.id === item.id)!.attributes.uri.value : '',
            }))
    }

    // Move row up or down
    function handleMove(id: string, up: boolean) {
        if (disabled)
            return
        setDisabled(true)
        let userDataLocal: DrupalEntity = JSON.parse(JSON.stringify(state.user.data))
        let docs = userDataLocal.relationships.field_club_documents.data
        const row = docs.find((x: JSONAPITypeId) => x.id === id)
        const idx = docs.indexOf(row)
        if ((idx === 0 && up) || (idx === docs.length - 1 && !up))
            return
        docs[idx] = docs[up ? idx - 1 : idx + 1]
        docs[up ? idx - 1 : idx + 1] = row
        nodeCRUD(state, dispatch, CRUD.Update, userDataLocal)
    }

    // Show document
    function handleViewClubDocument(id: string) {
        if (disabled)
            return
        const url = `${BACKEND}${state.allFiles.find(x => x.id === id)!.attributes.uri.url}`
        navigate('/practicereport', { state: url })
    }

    // Document has been renamed
    function handleProcessRowUpdate(updatedRow: GridRowModel) {
        if (disabled)
            return
        setDisabled(true)
        let userDataLocal: DrupalEntity = JSON.parse(JSON.stringify(state.user.data))
        userDataLocal.relationships.field_club_documents.data.find((x: JSONAPITypeId) => x.id === updatedRow.id)!.meta!.description = updatedRow.description
        nodeCRUD(state, dispatch, CRUD.Update, userDataLocal)
        return updatedRow // required by DataGrid
    }

    // Delete document
    function handleDelete(id: string) {
        dispatch(getActionSetConfirm(t('ClubDocuments03'), 'ok', () => {
            if (disabled)
                return
            setDisabled(true)
            let userDataLocal: DrupalEntity = JSON.parse(JSON.stringify(state.user.data))
            userDataLocal.relationships.field_club_documents.data = userDataLocal.relationships.field_club_documents.data.filter((x: JSONAPITypeId) => x.id !== id)
            nodeCRUD(state, dispatch, CRUD.Update, userDataLocal)
        }))
    }

    // Row clicked. Show document
    function handleRowClick(params: GridRowParams) {
        // handleViewClubDocument(params.id.toString())
        if (clickTimeout.current) {
            clearTimeout(clickTimeout.current);
            clickTimeout.current = null;
        }

        clickTimeout.current = setTimeout(() => {
            log.info(`Row clicked: ${params.id}`);
            handleViewClubDocument(params.id.toString());
            clickTimeout.current = null;
        }, 250); // Delay in milliseconds
    };

    // Row double clicked - goto edit mode to edit document name
    const handleRowDoubleClick = (_params: GridRowParams) => {
        if (clickTimeout.current) {
            clearTimeout(clickTimeout.current);
            clickTimeout.current = null;
        }
    };

    // Upload document
    async function handleClubDocumentFileChange(event: React.ChangeEvent<HTMLInputElement>) {
        // event.preventDefault();
        log.info('handleClubDocumentFileChange, upload type: ' + event.target.files![0].type + '. Name: ' + event.target.files![0].name + '. Size: ' + event.target.files![0].size);
        if (event.target.files && event.target.files.length > 0) {
            const mimeType = mime.getType(event.target.files[0].name) || ''
            // Check MIME type and size
            if (mimeTypeClubDocuments.includes(mimeType) && event.target.files[0].size < 2000000) {
                setDisabled(true)
                // add new file to users list of "field_club_documents" and return new list of "field_club_documents"
                // const resp = await addFileToNode(state, dispatch, event.target.files[0], state.user.data, "field_club_documents")
                // Upload file
                const resp = await nodeCRUD(state, dispatch, CRUD.Create, state.user.data, event.target.files[0], "field_club_documents")
                if (!resp.data) {
                    dispatch(getActionSetConfirm(resp))
                    return resp
                }

                // get the file to the global file list
                const action: ActionSetState = { type: 'setState', state: { ...state, allFiles: [...state.allFiles, resp.data[resp.data.length - 1]] } }
                dispatch(action)
                
                // Tie the file to the user entity
                // set initial description equal to file name
                const field_club_documents_new_element: JSONAPITypeId = {
                    id: resp.data[resp.data.length - 1].id,
                    type: 'file--file',
                    meta: { description: event.target.files[0].name }
                }

                // state.user.data.relationships.field_club_documents still holds the 'old' list of club documents before file above was added to list
                const userData: DrupalEntity = {
                    type: 'user--user',
                    id: state.user.data.id,
                    relationships: {
                        field_club_documents: {
                            data: state.user.data.relationships.field_club_documents.data.concat(field_club_documents_new_element)
                        }
                    }
                }
                await nodeCRUD(state, dispatch, CRUD.Update, userData)
            } else {
                dispatch(getActionSetConfirm(t('AlertMsg36')));
            }
        }
    };

    return (
        <Box sx={{ width: '90%', maxWidth: '700px', margin: 'auto', paddingTop: 2 }}>
            <Typography paddingBottom={2} sx={{ fontFamily: 'PT Sans, sans-serif', color: theme.palette.primary.main, fontSize: { xs: '20px', sm: '25px' }, fontWeight: 'bold' }}>
                {t('ClubDocuments02')}
            </Typography>
            <Divider sx={{ marginBottom: 3 }} />

            {
                state.user.data.attributes.field_user_type === UserType.clubadmin
                && <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    sx={{ marginBottom: 3 }}
                    disabled={disabled}
                >
                    {t('CRUDList08')}
                    <VisuallyHiddenInput
                        type="file"
                        onChange={(event) => handleClubDocumentFileChange(event)}
                    />
                </Button>
            }

            <DataGrid
                rows={rows}
                columns={state.user.data.attributes.field_user_type === UserType.clubadmin ? columns.concat(columnsActions) : columns}
                columnVisibilityModel={{ id: false }}
                disableColumnMenu
                rowHeight={30}
                processRowUpdate={(updatedRow, _originalRow) => handleProcessRowUpdate(updatedRow)}
                onRowClick={handleRowClick}
                onRowDoubleClick={handleRowDoubleClick}
            />
        </Box>
    )
}