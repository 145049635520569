import { useContext } from "react";
import PracticeProgramForm from "./PracticeProgramForm";
import PracticeProgramSelectedExercises from "./PracticeProgramSelectedExercises";
import { Box, Grid, Divider } from "@mui/material"; // Import Divider
import { Context } from "../App";
import { TypeContext } from "../misc/Types";

export default function PracticeProgram() {
    const { state } = useContext(Context) as TypeContext;
    return (
        <Grid container direction="row" alignItems="stretch" style={{ marginBottom: 150 }} >
            {!state.portrait && (<Divider orientation="vertical" flexItem sx={{ minHeight: '100vh' }} />)}
            <Grid item xs> {/* Wrap Card in a Grid item */}
                <Box>
                    <PracticeProgramForm />
                </Box>
                <PracticeProgramSelectedExercises /> 
            </Grid>
        </Grid>
    );
}