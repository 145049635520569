import { Fragment, useContext } from "react";
import { ExerciseSelected, OperationMode, TypeContext } from "../misc/Types";
import { Context } from "../App";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import log from "../misc/Logger";
import { useTranslation } from "react-i18next";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import PlayCard from "./PlayCard";

// list selected exercises for current practic or current practice draft
export default function PracticeProgramSelectedExercises() {
  const { state, } = useContext(Context) as TypeContext;
  const theme = useTheme();
  log.debug('PracticeProgramSelectedExercises');
  const { t } = useTranslation();

  let startDateTime = new Date(state.curPractice.date)
  let endDateTime = new Date(state.curPractice.date)

  // show selected exercises for current practice or for current practice draft
  let sourceList: ExerciseSelected[] = state.curPractice.selectedExercises // assume we handle exercise for practice
  if (state.operationMode === OperationMode.preplannedPractice) {
    sourceList = state.curPreplannedPractice.selectedExercises
    // setHours to have hours in local time and show 0. Otherwise, we show 1 because time is in GMT and I am in  GMT +1
    startDateTime = new Date(new Date(0).setHours(0, 0, 0, 0))
    endDateTime = new Date(new Date(0).setHours(0, 0, 0, 0))
  }

  return (
    <Fragment>
      {sourceList.length > 0 ? (
        // Use when we have retrieved data
        <Fragment>
          {
            sourceList
              .map((exerciseSelected: ExerciseSelected, index: number) => {
                startDateTime = new Date(endDateTime);
                endDateTime.setTime(startDateTime.getTime() / 1 + 60 * 1000 * (exerciseSelected.durationMinutes || 0));
                if (!exerciseSelected.exercise)
                  exerciseSelected.exercise = state.allExercises.find(x => x.attributes.drupal_internal__nid === exerciseSelected.drupal_internal__nid)!
                // if operation mode is practiceDraft then only show endtime. Practice drafs do not work with absolut times
                // assume operation mode 'exercise'
                let startAndStopTime = `${startDateTime.getHours().toString().padStart(2, '0')}:${startDateTime.getMinutes().toString().padStart(2, '0')}-${endDateTime.getHours().toString().padStart(2, '0')}:${endDateTime.getMinutes().toString().padStart(2, '0')}`
                if (state.operationMode === OperationMode.preplannedPractice)
                  startAndStopTime = `${startDateTime.getHours().toString().padStart(2, '0')}:${startDateTime.getMinutes().toString().padStart(2, '0')}`
                return (
                  <Box
                    // ref={index === sourceList.length - 1 ? refButtomOfComponent : null} // keep ref to buttom of view!
                    key={index}
                    margin={1}
                  >
                    <PlayCard
                      index={index}
                      play={exerciseSelected.exercise}
                      startAndStopTime={startAndStopTime}
                    />
                  </Box>
                );
              })}
          {
            // Allow for space below last exercise so there is room for pop up keyboard
            state.nativeApp && <Box height={250}></Box>
          }
        </Fragment>
      ) :
        (
          // Use when no drills are selected
          <Box sx={{ width: '90%', maxWidth: '700px', margin: 'auto', textAlign: 'center', paddingTop: 1 }}>
            <Typography sx={{ paddingTop: 0, color: theme.palette.text.secondary, fontSize: 13 }}>
              {t('PracticeProgramSelectedExercises03')}
              <IconButton
                sx={{
                  verticalAlign: 'middle',
                  padding: 0,
                  marginX: 0.5,
                  border: '1px solid #DD6F20',
                  borderRadius: '50%',
                  width: 30,
                  height: 30,
                  backgroundColor: '#DD6F20',
                  pointerEvents: 'none', // disables clicking and pointer events
                  '&:hover': {
                    backgroundColor: '#DD6F20',
                  },
                }}
              >
                <AddRoundedIcon sx={{ color: 'white', fontSize: 20 }} />
              </IconButton>{' '}
              {t('PracticeProgramSelectedExercises04')}
            </Typography>
          </Box>
        )
      }
    </Fragment>
  );
}
