import { Drawer, Box, Typography, ListItemButton, IconButton, Tooltip, styled, Avatar, Divider } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material";
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import FolderRoundedIcon from '@mui/icons-material/FolderRounded';
import { getConfigValue, getImageURL, logout, reloadApp } from "../misc/Functions";
import { Context } from "../App";
import { ActionShowPracticeProgram, TypeContext, UserType } from "../misc/Types";
import { CDN, DRUPALENTITYINIT, ICB_USER_ID } from "../misc/Constants";
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import ArticleIcon from '@mui/icons-material/Article';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import SortByAlphaRoundedIcon from '@mui/icons-material/SortByAlphaRounded';
import GroupWorkOutlinedIcon from '@mui/icons-material/GroupWorkOutlined';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import Groups2 from '@mui/icons-material/Groups2';
import SportsBasketballOutlinedIcon from '@mui/icons-material/SportsBasketballOutlined';
import SportsBasketballRoundedIcon from '@mui/icons-material/SportsBasketballRounded';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import NextPlanOutlinedIcon from '@mui/icons-material/NextPlanOutlined';
import NextPlanIcon from '@mui/icons-material/NextPlan';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import WorkspacePremiumRoundedIcon from '@mui/icons-material/WorkspacePremiumRounded';
import RefreshIcon from '@mui/icons-material/Refresh';
import { AccountCircleRounded } from "@mui/icons-material";
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import DialogTeamPlanStepper from "./DialogTeamPlanStepper";

export interface PropsMenuMain {
    open: boolean;
    onClose: () => void;
    anchorEl: null | HTMLElement;
}

const StyledDrawer = styled(Drawer)(() => ({
    '& .MuiDrawer-paper': {
        minWidth: 250,
        maxWidth: '80%',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
}));

export function MenuMain(props: PropsMenuMain) {
    const { open, onClose } = props;
    const navigate = useNavigate();
    const { t } = useTranslation();
    const theme = useTheme();

    const [openDialogTeamPlanStepper, setOpenDialogTeamPlanStepper] = useState(false);

    const { state, dispatch } = useContext(Context) as TypeContext;
    const [selectedPath, setSelectedPath] = useState(location.pathname);
    // const [openDialogTeamPlanStepper, setOpenDialogTeamPlanStepper] = useState(false);

    const fileIDAuthorImage = state.user.data.relationships?.user_picture?.data?.id;
    const authorImage = fileIDAuthorImage
        && state.allFiles.find(x => x.id === fileIDAuthorImage)?.attributes.uri.url;

    useEffect(() => {
        setSelectedPath(location.pathname);
    }, [location.pathname]);

    const handleCloseDrawer = () => {
        onClose();
    };

    /** ACTION HELPERS **/
    const navigateAndClose = (path: string) => {
        navigate(path);
        handleCloseDrawer();
    };

    /** MENU DEFINITION **/
    const menuSections = [
        // --- SECTION 1: Drills / Practices / Plays / Playbooks ---
        {
            divider: false,
            items: [
                {
                    text: 'DrawerLandscape01',
                    icon: <SportsBasketballOutlinedIcon />,
                    selectedIcon: <SportsBasketballRoundedIcon sx={{ color: theme.palette.secondary.main }} />,
                    action0: () => navigateAndClose('/icbdrills'),
                    action1: () => navigateAndClose('/exercisecreate'),
                    tooltipCreate: 'DrawerLandscape04',
                    createButtonColor: theme.palette.secondary.main,
                    isVisible: true,
                },
                {
                    text: 'DrawerLandscape02',
                    icon: <FolderOpenOutlinedIcon />,
                    selectedIcon: <FolderRoundedIcon sx={{ color: theme.palette.secondary.main }} />,
                    action0: () => navigateAndClose('/practices'),
                    action1: () => {
                        navigate('/icbdrills');
                        dispatch({ type: 'showPracticeProgram', show: true });
                        handleCloseDrawer();
                    },
                    tooltipCreate: 'Exercises01',
                    createButtonColor: theme.palette.secondary.main,
                    isVisible: true,
                },
                {
                    text: 'DrawerLandscape14',
                    icon: (
                        <img
                            src={theme.palette.mode === 'dark'
                                ? '/images/playbuilder/PlaysIconWhite.svg'
                                : '/images/playbuilder/PlaysIcon.svg'}
                            alt="Plays"
                            style={{ width: 24, height: 24 }}
                        />
                    ),
                    selectedIcon: (
                        <img
                            src={theme.palette.mode === 'dark'
                                ? '/images/playbuilder/PlaysIconWhite.svg'
                                : '/images/playbuilder/PlaysIconBlue.svg'}
                            alt="Plays"
                            style={{ width: 24, height: 24 }}
                        />
                    ),
                    action0: () => navigateAndClose('/icbplays'),
                    action1: () => navigateAndClose('/createplay'),
                    tooltipCreate: 'ICBAppBar13',
                    createButtonColor: theme.palette.primary.main,
                    isVisible: true,
                },
                {
                    text: 'DrawerLandscape15',
                    icon: <ArticleOutlinedIcon />,
                    selectedIcon: <ArticleIcon sx={{ color: theme.palette.primary.main }} />,
                    action0: () => navigateAndClose('/myplaybooks'),
                    action1: () => {
                        dispatch({ type: 'setPlaybook', playbook: DRUPALENTITYINIT });
                        dispatch({ type: 'showPracticeProgram', show: true });
                        navigateAndClose('/icbplays');
                    },
                    tooltipCreate: 'ICBAppBar14',
                    createButtonColor: theme.palette.primary.main,
                    isVisible: true,
                },
            ],
        },
        // --- SECTION 2: Preplanned Practices (clubadmin only) ---
        {
            divider: state.user.data.attributes.field_user_type === UserType.clubadmin || state.user.data.id === ICB_USER_ID,
            items: [
                {
                    text: 'DrawerLandscape19',
                    icon: <NextPlanOutlinedIcon />,
                    selectedIcon: <NextPlanIcon sx={{ color: theme.palette.mode === 'light' ? '#3095ca' : 'white' }} />,
                    action0: () => navigateAndClose('/preplannedpractices'),
                    action1: () => {
                        const actionShowPracticeProgram: ActionShowPracticeProgram = { type: 'showPracticeProgram', show: true }
                        dispatch(actionShowPracticeProgram);
                        navigateAndClose('/icbpreplannedpractices')
                    },
                    tooltipCreate: 'PreplannedPractices02',
                    createButtonColor: theme.palette.mode === 'light' ? '#3095ca' : 'white',
                    isVisible: state.user.data.attributes.field_user_type === UserType.clubadmin || state.user.data.id === ICB_USER_ID,
                },
            ],
        },
        // --- SECTION 3: Practice Generator (optional for all) ---
        {
            divider: true,
            items: [
                {
                    text: 'DrawerLandscape18',
                    textPermanentColor: '#DD6F20',
                    icon: <AutoFixHighOutlinedIcon sx={{ color: '#DD6F20' }} />,
                    // action: handleOpenTeamPlanStepper, // If using a Dialog
                    action: () => {
                        setOpenDialogTeamPlanStepper(true);
                        handleCloseDrawer();
                    },
                    isVisible: true,
                },
            ],
        },
        // --- SECTION 4: Concepts, Reports, Categories, etc. ---
        {
            divider: true,
            items: [
                {
                    text: 'DrawerLandscape13',
                    icon: <LightbulbOutlinedIcon />,
                    selectedIcon: <LightbulbIcon sx={{ color: theme.palette.secondary.main }} />,
                    action: () => navigateAndClose('/concepts'),
                    isVisible: true,
                },
                {
                    text: 'DrawerLandscape03',
                    icon: <InsertChartOutlinedIcon />,
                    selectedIcon: <InsertChartIcon sx={{ color: theme.palette.secondary.main }} />,
                    action: () => navigateAndClose('/formgetpracticereport'),
                    isVisible: true,
                },
                {
                    text: 'DrawerLandscape11',
                    icon: <ArticleOutlinedIcon />,
                    selectedIcon: <ArticleIcon sx={{ color: theme.palette.secondary.main }} />,
                    action: () => navigateAndClose('/clubdocuments'),
                    isVisible: ['clubadmin', 'club'].includes(state.user.data.attributes.field_user_type),
                },
                {
                    text: 'DrawerLandscape05',
                    icon: <SortByAlphaRoundedIcon />,
                    selectedIcon: <SortByAlphaRoundedIcon sx={{ color: theme.palette.secondary.main }} />,
                    action: () => navigateAndClose('/groups'),
                    isVisible: ['pro', 'clubadmin'].includes(state.user.data.attributes.field_user_type),
                },
                {
                    text: 'DrawerLandscape10',
                    icon: <GroupWorkOutlinedIcon />,
                    selectedIcon: <GroupWorkIcon sx={{ color: theme.palette.secondary.main }} />,
                    action: () => navigateAndClose('/myteams'),
                    isVisible: true,
                },
                {
                    text: 'DrawerLandscape06',
                    icon: <GroupsOutlinedIcon />,
                    selectedIcon: <Groups2 sx={{ color: theme.palette.secondary.main }} />,
                    action: () => navigateAndClose('/coaches'),
                    isVisible: state.user.data.attributes.field_user_type === 'clubadmin',
                },
                {
                    text: 'DrawerLandscape20',
                    icon: <PersonAddAltRoundedIcon />,
                    selectedIcon: <PersonAddAltRoundedIcon sx={{ color: theme.palette.secondary.main }} />,
                    action: () => navigateAndClose('/promotionmar25'),
                    // dont show if clubadmin or state.field_defaults.promotionmar25.grant[0] === 100
                    isVisible: state.uiSettings.showButtonToOpenInvitePromotionMar25
                        && getConfigValue(state, "enablePromotionMar25")
                        && state.field_defaults?.promotionmar25?.grant?.[0] !== 100
                },
            ],
        },
    ];

    /** RENDER HELPERS **/
    const isSelected = (path: string) => selectedPath === path;

    /** RENDER HELPERS **/
    const renderMenuItem = (item: any) => {
        if (!item.isVisible) return null;

        const selected = isSelected(item.path);
        const activeIconColor = item.createButtonColor || theme.palette.secondary.main;

        // If the item has both action0 + action1 => it's the "dual action" type
        // Else use item.action for single action
        const primaryAction = item.action0 || item.action;
        const createAction = item.action1;

        return (
            <Box key={item.text} sx={{ display: 'flex', alignItems: 'center', pb: 0.5 }}>
                <ListItemButton
                    onClick={() => (typeof primaryAction === 'function' ? primaryAction() : navigateAndClose(primaryAction))}
                    selected={selected}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        borderRadius: 2,
                        width: '100%',
                        p: '8px',
                    }}
                >
                    {selected ? item.selectedIcon ?? item.icon : item.icon}
                    <Typography
                        sx={{
                            fontSize: 14,
                            ml: 1,
                            whiteSpace: 'nowrap',
                            fontWeight: selected ? 'bold' : 'normal',
                            color: item.textPermanentColor || (selected ? activeIconColor : ''),
                        }}
                    >
                        {t(item.text)}
                    </Typography>
                </ListItemButton>

                {/* Optional Create Button */}
                {createAction && (
                    <IconButton aria-label="create-action" sx={{ ml: 1 }}>
                        <Tooltip title={t(item.tooltipCreate)} placement="right">
                            <AddBoxRoundedIcon
                                onClick={createAction}
                                sx={{ color: activeIconColor }}
                            />
                        </Tooltip>
                    </IconButton>
                )}
            </Box>
        );
    };

    /** MAIN RETURN **/
    return (
        <>
            <StyledDrawer anchor="left" open={open} onClose={onClose}>
                {/* -- TOP SECTION: Logo, User Info -- */}
                <Box sx={{ p: 2, flexGrow: 1 }}>
                    <Box mt="10px" ml="10px">
                        <img
                            height={42}
                            src={getImageURL(theme.palette.mode === 'dark' ? 'logoICBWhite.webp' : 'logoICBBlue.webp')}
                            alt="Logo"
                        />
                    </Box>

                    {/* User Info */}
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            backgroundColor: '#f9f9f9',
                            borderRadius: 2,
                            p: 1,
                            mt: 2,
                            mb: 1,
                        }}
                    >
                        {authorImage ? (
                            <img
                                src={`${CDN}/${authorImage}.webp`}
                                height="36"
                                width="36"
                                style={{ borderRadius: '50%', marginRight: '8px' }}
                                alt="User"
                            />
                        ) : (
                            <Avatar
                                sx={{
                                    bgcolor: theme.palette.secondary.main,
                                    width: 36,
                                    height: 36,
                                    fontSize: '0.9rem',
                                    mr: 1,
                                }}
                            >
                                {state.user.data.attributes.display_name?.substring(0, 2).toUpperCase()}
                            </Avatar>
                        )}
                        <Box>
                            <Typography sx={{ fontWeight: 'bold', fontSize: 14, color: 'black' }}>
                                {state.user.data.attributes.field_first_name} {state.user.data.attributes.field_last_name}
                            </Typography>
                            <Typography sx={{ fontSize: 12, color: 'black' }}>
                                {state.user.data.attributes.field_user_type === 'clubadmin'
                                    ? t('MainMenu02')
                                    : state.user.data.attributes.field_user_type === 'club'
                                        ? t('MainMenu03')
                                        : state.user.data.attributes.field_user_type === 'free'
                                            ? t('MainMenu00')
                                            : t('MainMenu01')}
                            </Typography>
                        </Box>
                    </Box>

                    {/* RENDER SECTIONS */}
                    {menuSections.map((section, idx) => (
                        <Box key={idx}>
                            {section.divider && <Divider sx={{ mb: 0.5 }} />}
                            {section.items.map(renderMenuItem)}
                        </Box>
                    ))}
                </Box>

                {/* -- UPGRADE IF A FREE USER -- */}
                {state.user.data.attributes.field_user_type === UserType.free && (
                    <Box paddingX={1} paddingBottom={0.5} display="flex" alignItems="center">
                        <ListItemButton
                            onClick={() => navigateAndClose('/setsubscription')}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: open ? 'flex-start' : 'center',
                                borderRadius: 2,
                                width: '100%',
                                padding: '8px',
                            }}
                        >
                            <WorkspacePremiumRoundedIcon sx={{ color: theme.palette.secondary.main }} />
                            {open && (
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        whiteSpace: 'nowrap',
                                        ml: 1,
                                        color: theme.palette.secondary.main,
                                    }}
                                >
                                    {t('DrawerLandscape12')}
                                </Typography>
                            )}
                        </ListItemButton>
                    </Box>
                )}

                <Divider sx={{ mb: 0.5, width: '90%', mx: 'auto' }} />

                {/* -- REFRESH & PROFILE/LOGOUT SECTION -- */}
                <Box sx={{ px: 1, pb: 3 }}>
                    {/* Refresh */}
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <ListItemButton
                            onClick={() => reloadApp('MainMenu - use click reload', state.nativeApp, location.pathname)}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                borderRadius: 2,
                                width: '100%',
                                p: '8px',
                            }}
                        >
                            <RefreshIcon />
                            <Typography sx={{ fontSize: 14, ml: 1, whiteSpace: 'nowrap' }}>
                                {t('MainMenu04')}
                            </Typography>
                        </ListItemButton>
                    </Box>

                    {/* Profile & Logout */}
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <ListItemButton
                            onClick={() => {
                                navigate('/profile');
                                handleCloseDrawer();
                            }}
                            selected={selectedPath === '/profile'}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                borderRadius: 2,
                                width: '100%',
                                p: '8px',
                            }}
                        >
                            {selectedPath === '/profile' ? (
                                <AccountCircleRounded sx={{ color: theme.palette.secondary.main }} />
                            ) : (
                                <AccountCircleRounded />
                            )}
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    ml: 1,
                                    whiteSpace: 'nowrap',
                                    fontWeight: selectedPath === '/profile' ? 'bold' : 'normal',
                                    color: selectedPath === '/profile' ? theme.palette.secondary.main : '',
                                }}
                            >
                                {t('DrawerLandscape07')}
                            </Typography>
                        </ListItemButton>
                        <IconButton onClick={() => logout(state, dispatch, navigate)} aria-label="logout">
                            <Tooltip title={t('DrawerLandscape09')} placement="right">
                                <LogoutRoundedIcon />
                            </Tooltip>
                        </IconButton>
                    </Box>
                </Box>
            </StyledDrawer>

            <DialogTeamPlanStepper
                open={openDialogTeamPlanStepper}
                onClose={() => setOpenDialogTeamPlanStepper(false)}
            />
        </>
    );
}

