import { Fragment, useContext, useEffect, useState } from "react";
import { Context } from "../App";
import { ActionSetState, DrupalEntity, TypeContext, TypeState } from "../misc/Types";
import { Box, Card, Divider, Grid, IconButton, Typography, useTheme } from "@mui/material";
import log from "../misc/Logger";
import { getActionSetConfirm, getAllContentEntities, icbControllerGenerel02 } from "../misc/Functions";
import { useTranslation } from "react-i18next";
import UserPictureAndName from "./UserPictureAndName";
import Grid2 from "@mui/material/Unstable_Grid2";
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import CopyToClipboardButton from "./CopyToClipboardButton";

export default function Coaches() {
    const { state, dispatch } = useContext(Context) as TypeContext;
    const { t } = useTranslation();
    log.debug('Coaches');
    const theme = useTheme();

    const [disabled, setDisabled] = useState(false);
    const [numberOfCoaches, setNumberOfCoaches] = useState(0)
    const [coachesAccepted, setCoachesAccepted] = useState<Array<DrupalEntity>>([])
    const [coachesNotAccepted, setCoachesNotAccepted] = useState<Array<DrupalEntity>>([])

    // // coaches of club. Accepted and not accepted
    // const getCoachesAccepted = () => state.user.data.relationships.field_club_coaches?.data.map((x: DrupalEntity) => state.allUsers.find(y => y.id === x.id)).sort(compareFn) || [];
    // const getCoachesNotAccepted = () => state.allUsers.filter(x => x.relationships.field_my_club_admin?.data?.id === state.user.data.id && !x.attributes.field_club_admin_accept).sort(compareFn) || [];

    useEffect(() => {
        /*
        we get users using two call becuase I don't know how to do a OR filter in JSON
        in this case with a clubadmin we have to make sure we have the user profile set!
        */
        if (state.user.data.type !== '') {
            const numberOfCoachesLocal = state.user.data.relationships.field_club_coaches.data.length
            setNumberOfCoaches(numberOfCoachesLocal)
            const coachesAcceptedLocal = state.allUsers.filter(x => x.relationships.field_my_club_admin.data && x.attributes.field_club_admin_accept).sort(compareFn)
            setCoachesAccepted(coachesAcceptedLocal)
            const coachesNotAcceptedLocal = state.allUsers.filter(x => x.relationships.field_my_club_admin.data && !x.attributes.field_club_admin_accept).sort(compareFn)
            setCoachesNotAccepted(coachesNotAcceptedLocal)
            setDisabled(false) // enable buttons to act on next coach once state.allUsers has been updated
        }
    }, [state.allUsers])

    // Sort array of DrupalEntity with users by display_name
    function compareFn(aUser: DrupalEntity, bUser: DrupalEntity) {
        let a = aUser.attributes.display_name.toLowerCase();
        let b = bUser.attributes.display_name.toLowerCase();
        if (a < b) {
            return -1;
        } else if (a > b) {
            return 1;
        }
        return 0;
    }

    // function removeFromNodeListUser(id: string) {
    //     let action: ActionRemoveFromNodeListUser = {
    //         type: 'removeFromNodeListUser',
    //         id: id,
    //     };
    //     dispatch(action);
    // }

    // Accecpt or remove coach from list of Club Coaches
    function processCoachList(state: TypeState, dispatch: React.Dispatch<any>, coach: DrupalEntity, opr: string, diff: number) {
        setDisabled(true) // Better safe than sorry! Gabri managed to double click on add coach
        // diff: 0 is decline application, -1 remove already accepted coach, 1 add applicant coach
        // Add or remove coach if confirmed

        // Get text for confirmation dialog
        let msg = t('Coaches08'); // assume remove
        if (diff === 0)
            msg = t('Coaches00'); // decline
        if (diff === 1)
            msg = t('Coaches01'); // accept
        msg += coach.attributes.display_name

        // Show confirmation dialog and execute code of accepted
        dispatch(getActionSetConfirm(msg, 'ok', async () => {
            setDisabled(true)
            dispatch({ type: 'setBackdrop', diff: 1 })
            log.info('Aggree: ' + msg);
            const resp = await icbControllerGenerel02(state, {
                "opr": opr, // update_coach_list or disregard_coach_application
                "coach": coach.attributes.drupal_internal__uid,                         // coach that is added or removed from list
                "add": diff === 1,
            })
            if (resp.ok) {
                // refresh list of users the clubadmin cares about
                const filter = `?filter[field_my_club_admin.meta.drupal_internal__target_id]=${state.user.login.current_user.uid}&include=user_picture`;
                getAllContentEntities(state, dispatch, 'user--user', filter)
                // get clubadmin so list of coaches is updated
                getAllContentEntities(state, dispatch, 'user--user', `?filter[uid]=${state.user.login.current_user.uid}`)
                dispatch({ type: 'setBackdrop', diff: 0 })
            } else {
                dispatch(getActionSetConfirm(resp.error))
                setDisabled(false)
                dispatch({ type: 'setBackdrop', diff: 0 })
                return
            }
            if (diff < 1) {
                // remove coach from list of users
                const action: ActionSetState = { type: 'setState', state: {...state, allUsers: state.allUsers.filter(x => x.attributes.drupal_internal__uid !== coach.attributes.drupal_internal__uid)}}
                dispatch(action)
            }
            // if (diff === -1) {
            //     // Accepted coach is removed
            //     // await fetchNodes('user', 'user', `?filter[id]=${state.user.data.id}`, state, dispatch).then((data) => dispatch({ type: 'setUserUsers', collected: data }));
            //     getAllContentEntities(state, dispatch, 'user--user', )
            // }
            // if (diff === 0) {
            //     // Not accepted coach is removed
            //     removeFromNodeListUser(coach.id!);
            // }
            // if (diff === 1) {
            //     // Coach accepted.
            //     await fetchNodes('user', 'user', `?filter[id]=${state.user.data.id}`, state, dispatch).then((data) => dispatch({ type: 'setUserUsers', collected: data }));
            //     await fetchNodes('user', 'user', `?filter[id]=${coach.id}`, state, dispatch).then((data) => dispatch({ type: 'setUserUsers', collected: data }));
            // }
        }));
        setDisabled(false)
    }

    return (
        <Fragment>

            <Box sx={{ width: '90%', maxWidth: '700px', margin: 'auto', paddingTop: 2 }}>
                <Typography paddingBottom={2} sx={{ fontFamily: 'PT Sans, sans-serif', color: theme.palette.primary.main, fontSize: { xs: '20px', sm: '25px' }, fontWeight: 'bold' }}>
                    {t('Coaches09')}
                </Typography>
                <Divider />

                <Grid2 container direction="column" margin={1} spacing={1}>
                    {/* Header */}
                    <Grid2 xs={12}>
                        <Typography textAlign='center' color='primary' sx={{ fontWeight: 'bold', fontFamily: 'PT Sans, sans-serif', fontSize: { xs: 16, sm: 18 } }} >
                            {t('Coaches02')} {state.user.data.attributes.field_club_name}
                        </Typography>
                        <Typography textAlign={'center'} sx={{ fontSize: { xs: 14, sm: 16 }, fontFamily: 'PT Sans, sans-serif', color: theme.palette.text.secondary }}>
                            {t('Coaches03')} {numberOfCoaches}
                        </Typography>
                        <Box sx={{ marginTop: 2 }}>
                            <CopyToClipboardButton />
                        </Box>
                    </Grid2>

                    {/* Coaches pending approval */}
                    <Card elevation={0}>
                        <Grid2 xs={12} mt={5}>
                            <Typography style={{ textTransform: 'none', fontSize: '12px', fontWeight: 'bold', color: theme.palette.primary.main }}>
                                {t('Coaches04')}
                            </Typography>
                            <Divider />
                        </Grid2>
                        {
                            coachesNotAccepted.length > 0 ?
                                coachesNotAccepted.map((coach, index) => (
                                    <Fragment key={coach.id}>
                                        <Grid container marginTop={1}>
                                            <Grid item xs={8} paddingLeft={3}>
                                                <UserPictureAndName entity={coach}></UserPictureAndName>
                                            </Grid>

                                            <Grid item xs={2} style={{ paddingLeft: '20px' }}>
                                                {/* Remove applying coach */}
                                                <IconButton
                                                    aria-label={`coaches_remove_applying_coach_${index}`}
                                                    disabled={disabled}
                                                    style={{ color: "#cc0404" }}
                                                    size="small"
                                                    onClick={() => processCoachList(state, dispatch, coach, "disregard_coach_application", 0)}
                                                >
                                                    <HighlightOffRoundedIcon />
                                                </IconButton>
                                            </Grid>

                                            <Grid item xs={2} style={{ paddingLeft: '20px' }}>
                                                {/* Add coach */}
                                                <IconButton
                                                    aria-label={`coaches_add_coach_${index}`}
                                                    disabled={disabled}
                                                    style={{ color: "#2cc414" }}
                                                    size="small"
                                                    onClick={() => processCoachList(state, dispatch, coach, "update_coach_list", 1)}
                                                >
                                                    <CheckCircleRoundedIcon />
                                                </IconButton>
                                            </Grid>

                                        </Grid>
                                        <Divider />
                                    </Fragment>
                                ))
                                :
                                <Typography textAlign={'center'} sx={{ fontSize: { xs: 12, sm: 15 }, fontFamily: 'PT Sans, sans-serif', color: theme.palette.text.secondary }} marginTop={0} marginBottom={1}>
                                    {t('Coaches05')}
                                </Typography>
                        }
                    </Card>

                    {/* Coaches of my club */}
                    <Card elevation={0} sx={{ marginTop: 0, }}>

                        <Grid2 xs={12} sx={{ marginTop: 5 }}>
                            <Typography style={{ textTransform: 'none', fontSize: '12px', fontWeight: 'bold', color: theme.palette.primary.main }}>
                                {t('Coaches06')}
                            </Typography>
                            <Divider />
                        </Grid2>


                        {
                            coachesAccepted.length > 0 ?
                                coachesAccepted.map((coach, index) => (
                                    <Fragment key={coach.id}>
                                        <Grid container direction="row" alignItems="center">
                                            <Grid item xs={10} paddingLeft={3}>
                                                <UserPictureAndName entity={coach} />
                                            </Grid>
                                            <Grid item xs={2} style={{ paddingLeft: '20px' }}>
                                                {/* Remove coach that has already been accepted */}
                                                <IconButton
                                                    aria-label={`coaches_remove_accepted_coach_${index}`}
                                                    disabled={disabled}
                                                    style={{ color: theme.palette.text.disabled }}
                                                    size="small"
                                                    onClick={() => processCoachList(state, dispatch, coach, "update_coach_list", -1)}
                                                >
                                                    <HighlightOffRoundedIcon />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        <Divider />
                                    </Fragment>
                                ))
                                :
                                <Typography textAlign={'center'} sx={{ fontSize: { xs: 12, sm: 15 }, fontFamily: 'PT Sans, sans-serif', color: theme.palette.text.secondary }} marginTop={1} marginBottom={1}>
                                    {t('Coaches07')}
                                </Typography>
                        }
                    </Card>
                </Grid2>
            </Box>
        </Fragment >
    )
}
